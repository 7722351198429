import { Button, Center, HStack, Image, Text, VStack } from "@chakra-ui/react";
import React, { useContext, useState, useEffect } from "react";
import { KanbanContext } from "src/contexts/KanbanContext";
import { Controller, useForm } from "react-hook-form";
import { Loading } from "semente-js";
import Icon from "src/components/base/Icon";
import { Props } from "./types";
import { ProjectContext } from "src/contexts/ProjectContext";
import { searchSuperIcon } from "src/assets/images";
import ProjectsService from "src/services/projects";
import { Company } from "src/types";

export const AddCompany: React.FC<Props> = () => {
  const { kanbanData, createTask, removeTask } = useContext(KanbanContext);
  const { currentProject, showProjectDialog } = useContext(ProjectContext);
  const [companyLoading, setCompanyLoading] = useState<string>();
  const [companies, setCompanies] = useState({
    data: [] as Company[],
    isLoading: true,
  });
  const defaultValues = {
    companies: (kanbanData?.data?.tasks || []).map((task) => task.company_id),
  };

  const { control, reset } = useForm({ defaultValues });

  useEffect(() => {
    if (currentProject.data) {
      ProjectsService.getCompaniesByProject(currentProject.data.id).then(
        (data) => setCompanies({ data, isLoading: false })
      );
    }
  }, [currentProject.data]);

  useEffect(() => {
    reset({
      companies: (kanbanData?.data?.tasks || []).map((task) => task.company_id),
    });
    setCompanyLoading(undefined);
  }, [kanbanData?.data, reset]);

  const handleAddCompany = async (company_id: string) => {
    setCompanyLoading(company_id);
    await createTask({ company_id });
  };

  const handleRemoveCompany = async (company_id: string) => {
    const task_id = kanbanData?.data?.tasks.find(
      (task) => task.company_id === company_id
    )?.id;

    if (task_id) {
      setCompanyLoading(company_id);
      await removeTask(task_id);
    }
  };

  return (
    <VStack
      maxW={"568px"}
      maxH={"70vh"}
      overflow={"auto"}
      borderTop={"1px solid"}
      borderColor={"Gray.$400"}
    >
      {!currentProject.isLoading && !companies.isLoading ? (
        <>
          <VStack
            spacing={"16px"}
            w={"100%"}
            alignItems={"flex-start"}
            p={"24px"}
          >
            {companies.data && companies.data?.length >= 1 ? (
              <>
                <VStack spacing={0} alignItems={"flex-start"}>
                  <Text fontSize={"L_lg"} fontFamily={"lato"} fontWeight={600}>
                    Empresas da {currentProject?.data?.name}
                  </Text>
                  <Text
                    fontSize={"L_sm"}
                    fontFamily={"lato"}
                    fontWeight={400}
                    mt={"4px !important"}
                    color={"Gray.$700"}
                  >
                    Adicione abaixo as empresas e seus consultores responsáveis
                    nesse projeto.
                  </Text>
                </VStack>

                <Controller
                  name={"companies"}
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange } }) => (
                    <VStack
                      w={"100%"}
                      spacing={"16px"}
                      my={"16px !important"}
                      borderRadius={"16px"}
                      border={"1px solid"}
                      borderColor={"Gray.$400"}
                      alignItems={"flex-start"}
                      px={"16px"}
                    >
                      {companies.data.map((company, index) => {
                        const companyAlredySelected = !!value?.find(
                          (companyS) => companyS === company.id
                        );

                        return (
                          <HStack key={company.id} w={"100%"}>
                            <Center
                              w={"40px"}
                              h={"40px"}
                              borderRadius={"50%"}
                              background={"Gray.$300"}
                            >
                              {company.image_url ? (
                                <Image
                                  src={company.image_url}
                                  borderRadius={"50%"}
                                  objectFit={"cover"}
                                  w={"100%"}
                                  h={"100%"}
                                />
                              ) : (
                                <Icon name="image" />
                              )}
                            </Center>
                            <HStack
                              flex={1}
                              py={"26px"}
                              ml={"12px"}
                              borderBottom={"1px solid"}
                              borderColor={
                                currentProject.data &&
                                companies.data.length - 1 === index
                                  ? "transparent"
                                  : "Gray.$400"
                              }
                              justifyContent={"space-between"}
                            >
                              <Text>{company.name}</Text>

                              {companyLoading === company.id ? (
                                <Text
                                  fontWeight={600}
                                  fontSize={"L_md"}
                                  cursor={"pointer"}
                                  color={"Tertiary.dark"}
                                >
                                  {"Alterando..."}
                                </Text>
                              ) : (
                                <Text
                                  fontWeight={600}
                                  fontSize={"L_md"}
                                  cursor={"pointer"}
                                  {...(!companyLoading && {
                                    onClick: companyAlredySelected
                                      ? () => handleRemoveCompany(company.id)
                                      : () => handleAddCompany(company.id),
                                  })}
                                  color={
                                    companyLoading
                                      ? "Gray.$400"
                                      : companyAlredySelected
                                        ? "Red.pure"
                                        : "Tertiary.dark"
                                  }
                                >
                                  {companyAlredySelected
                                    ? "Remover"
                                    : "Adicionar"}
                                </Text>
                              )}
                            </HStack>
                          </HStack>
                        );
                      })}
                    </VStack>
                  )}
                />
                <VStack
                  p={"16px"}
                  w={"100%"}
                  borderRadius={"12px"}
                  alignItems={"flex-start"}
                  background={"Gray.$200"}
                >
                  <Text fontSize={"L_md"} fontWeight={400} color={"Gray.$700"}>
                    Acima estão todas as empresas que estão no projeto{" "}
                    {currentProject?.data?.name}. Para adicionar outras
                    empresas, você precisa adicionar ao projeto antes.
                  </Text>
                </VStack>
              </>
            ) : (
              <VStack py={"32px"} px={"42px"}>
                <Image src={searchSuperIcon} alt={"Ícone de uma lupa"} />
                <Text
                  fontSize={"RH_xs"}
                  mt={"16px !important"}
                  fontWeight={600}
                  fontFamily={"raleway"}
                  textAlign={"center"}
                >
                  Aparentemente, ainda não temos empresas
                </Text>
                <Text
                  mt={"8px !important"}
                  mb={"16px !important"}
                  color={"Gray.$700"}
                  fontSize={"L_md"}
                  fontWeight={400}
                  textAlign={"center"}
                >
                  Tente adicionar mais empresas a este projeto
                </Text>
                <Button
                  minH={"48px"}
                  variant={"Primary"}
                  onClick={() => showProjectDialog("companies")}
                >
                  Gerenciar empresas
                </Button>
              </VStack>
            )}
          </VStack>
        </>
      ) : (
        <Center minH={"200px"}>
          <VStack alignItems={"center"}>
            <Loading
              height="100"
              width="100"
              color="#408EC5"
              secondaryColor="#408EC5"
              radius="12.5"
              ariaLabel="mutating-dots-loading"
              visible={true}
            />
          </VStack>
        </Center>
      )}
    </VStack>
  );
};
