import React, { useEffect, useState } from "react";
import { Props } from "./types";
import { Indicator } from "src/types";
import IndicatorService from "src/services/indicator";
import { Box, Button, Center, Text, VStack } from "@chakra-ui/react";
import { Loading } from "semente-js";
import IndicatorLine from "./IndicatorLine";
import { fadeInUp } from "src/animations";
import { motion } from "framer-motion";
import { Modal } from "src/components/base/Modal";
import { toast } from "react-toastify";
import { Button as SButton } from "semente-js";
import ChoiceIndicatorsDialog from "src/pages/Project/CreateProjectModal/ChoiceIndicatorsDialog";

const InidcatorTab: React.FC<Props> = ({ project_id }) => {
  const [indicatorToRemove, setIndicatorToRemove] = useState<string>();
  const [showAddIndicator, setShowAddIndicator] = useState(false);
  const [indicators, setIndicators] = useState({
    projectIndicators: [] as Indicator[],
    allIndicators: [] as Indicator[],
    isLoading: true,
  });

  useEffect(() => {
    IndicatorService.getAll(project_id).then((projectIndicators) =>
      IndicatorService.getAll().then((allIndicators) =>
        setIndicators({ allIndicators, projectIndicators, isLoading: false })
      )
    );
  }, [project_id]);

  const handleRemoveIndicator = async () => {
    const toRemove = indicatorToRemove;
    setIndicatorToRemove(undefined);

    if (toRemove) {
      try {
        setIndicators({ ...indicators, isLoading: true });

        await IndicatorService.removeFromProject({
          indicator_id: toRemove,
          project_id,
        });

        setIndicators({
          ...indicators,
          isLoading: false,
          projectIndicators: indicators.projectIndicators.filter(
            (indicator) => indicator.id !== toRemove
          ),
        });
      } catch (err) {
        toast.error("Ocorreu um erro ao remover o indicador");
      }
    }
  };

  const handleAddIndicators = async (values: Indicator["id"][]) => {
    try {
      setShowAddIndicator(false);
      setIndicators({ ...indicators, isLoading: true });

      const newIndicators = await IndicatorService.addToProject(
        project_id,
        values
      );

      setIndicators({
        ...indicators,
        projectIndicators: [...indicators.projectIndicators, ...newIndicators],
      });
    } catch (err) {
      toast.error("Ocorreu um erro ao adicionar o indicador");
    }
  };

  return (
    <motion.div {...fadeInUp} style={{ width: "100%" }}>
      {indicators.isLoading ? (
        <Center w={"100%"} minH={"300px"}>
          <Loading
            height="100"
            width="100"
            color="#408EC5"
            secondaryColor="#408EC5"
            radius="12.5"
            ariaLabel="mutating-dots-loading"
            visible={true}
          />
        </Center>
      ) : (
        <VStack w={"100%"} spacing={0}>
          {indicators.projectIndicators.map((indicator, index) => (
            <IndicatorLine
              indicator={indicator}
              handleRemoveIndicator={setIndicatorToRemove}
              isLast={indicators.projectIndicators.length - 1 === index}
            />
          ))}

          <Box alignSelf={"flex-start"}>
            <SButton
              color={"gray"}
              layout="rounded"
              iconName={"plus"}
              label={"Adicionar indicador"}
              onClick={() => setShowAddIndicator(true)}
            />
          </Box>
        </VStack>
      )}

      <Modal
        title={"Adicione indicadores ao projeto"}
        onClose={() => setShowAddIndicator(false)}
        isOpen={showAddIndicator}
      >
        <ChoiceIndicatorsDialog
          defaultValues={indicators.projectIndicators.map(({ id }) => id)}
          indicators={indicators.allIndicators}
          handleSubmit={handleAddIndicators}
          submitLabel={"Adicionar"}
          handleInsertIndicator={(newIndicator) =>
            setIndicators({
              ...indicators,
              allIndicators: [...indicators.allIndicators, newIndicator],
            })
          }
        />
      </Modal>

      <Modal title={"Excluir indicador"} isOpen={!!indicatorToRemove}>
        <VStack maxW={"568px"}>
          <VStack borderTop={"1px solid"} borderColor={"Gray.$300"} p={"24px"}>
            <Text flex={1} fontSize={"LMD"} fontWeight={500}>
              Tem certeza que deseja remover esse indicador do projeto? Você
              pode adicionar novamente depois.
            </Text>
          </VStack>

          <VStack
            borderTop={"1px solid"}
            borderColor={"Gray.$300"}
            p={"16px 24px"}
            w={"100%"}
          >
            <Button
              variant={"Primary"}
              borderRadius={"8px"}
              w={"100%"}
              onClick={handleRemoveIndicator}
            >
              Excluir indicador
            </Button>
            <Button
              onClick={() => setIndicatorToRemove(undefined)}
              variant={"Secondary"}
              borderRadius={"8px"}
              w={"100%"}
            >
              Voltar
            </Button>
          </VStack>
        </VStack>
      </Modal>
    </motion.div>
  );
};

export default InidcatorTab;
