import { Button, Center, HStack, Image, Text, VStack } from "@chakra-ui/react";
import React, { useContext } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Loading } from "semente-js";
import { toast } from "react-toastify";
import { Modal } from "src/components/base/Modal";
import QuantitySelector from "src/components/QuantitySelector";
import { ProjectContext } from "src/contexts/ProjectContext";
import { getColumns } from "src/pages/Kanban/common";
import KanbanService from "src/services/kanban";
import { motion } from "framer-motion";
import { Props } from "./types";
import Input from "src/components/base/Input/Index";
import { Task } from "src/types";
import { KanbanContext } from "src/contexts/KanbanContext";
import { ProjectViewContext } from "../Context";

export const defaultValues = {
  name: "",
  qtd_columns: 1,
  tasks: [{}] as Task[],
};

export const BoardCreation: React.FC<Props> = ({ isOpen, onClose }) => {
  const { currentProject, selectProject } = useContext(ProjectContext);
  const { refreshKanban } = useContext(KanbanContext);
  const { setCurrentTabIn } = useContext(ProjectViewContext);
  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm({ defaultValues });

  const handleSubmitForm: SubmitHandler<typeof defaultValues> = async (
    values
  ) => {
    try {
      if (currentProject.data) {
        const tasks = values.tasks.filter(
          (task) => task.company_id && task.consultant_id
        );
        const { id } = await KanbanService.create({
          ...values,
          tasks,
          project_id: currentProject.data.id,
        });

        toast.success("Kanban criado com sucesso!");
        selectProject(currentProject.data.id, true);
        refreshKanban(id);
        setCurrentTabIn(1);
        onClose();
      }
    } catch (err) {
      toast.error("Ocorreu um erro ao criar o quadro.");
    }
  };

  const columns = getColumns(watch("qtd_columns").toString());

  return (
    <Modal title="Criar quadro" onClose={onClose} isOpen={isOpen}>
      <VStack maxW={"568px"} borderTop={"1px solid"} borderColor={"Gray.$400"}>
        {isSubmitting ? (
          <Center minH={"200px"}>
            <VStack alignItems={"center"}>
              <Loading
                height="100"
                width="100"
                color="#408EC5"
                secondaryColor="#408EC5"
                radius="12.5"
                ariaLabel="mutating-dots-loading"
                visible={true}
              />
              <Text
                fontSize={"L_lg"}
                fontFamily={"lato"}
                mt={"4px !important"}
                fontWeight={600}
              >
                Aguarde, estamos criando seu quadro
              </Text>
            </VStack>
          </Center>
        ) : (
          <VStack w={"100%"}>
            <VStack p={"24px"} w={"100%"}>
              <Controller
                name={"name"}
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    {...field}
                    isInvalid={!!error}
                    placeholder={"Nome do quadro"}
                  />
                )}
              />

              <HStack
                mt={"16px !important"}
                justifyContent={"space-between"}
                w={"100%"}
                py={"12px"}
              >
                <Text fontWeight={600} fontSize={"L_md"}>
                  Quantidade de consultorias
                </Text>

                <Controller
                  name={"qtd_columns"}
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <QuantitySelector value={value} onChange={onChange} />
                  )}
                />
              </HStack>

              <VStack
                alignItems={"flex-start"}
                p={"16px"}
                pb={"0px"}
                mt={"24px !important"}
                w={"100%"}
                background={"Gray.$200"}
                borderRadius={"12px"}
              >
                <Text fontWeight={600} fontSize={"L_md"} mb={"8px !important"}>
                  Colunas
                </Text>

                {columns.map((column, index) => (
                  <motion.div
                    key={column.id}
                    animate={{ scale: 0.98 }}
                    initial={{ scale: 1.1 }}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Image
                      src={column.icon}
                      w={"24px"}
                      h={"24px"}
                      borderRadius={"50%"}
                      mr={"12px !important"}
                    />
                    <Text
                      w={"100%"}
                      py={"16px"}
                      borderBottom={"1px solid"}
                      borderColor={
                        columns.length - 1 === index
                          ? "transparent"
                          : "Gray.$400"
                      }
                    >
                      {column.name}
                    </Text>
                  </motion.div>
                ))}
              </VStack>
            </VStack>

            <VStack
              px={"24px"}
              py={"24px"}
              spacing={"8px !important"}
              w={"100%"}
              borderTop={"1px solid"}
              borderColor={"Gray.$400"}
            >
              <Button
                w={"100%"}
                variant={"Primary"}
                onClick={handleSubmit(handleSubmitForm)}
              >
                Criar quadro
              </Button>
            </VStack>
          </VStack>
        )}
      </VStack>
    </Modal>
  );
};
