import React, { useEffect, useState, useContext } from "react";
import { Props } from "./types";
import { Box, Button, Center, HStack, Text, VStack } from "@chakra-ui/react";
import Icon from "../../../components/base/Icon";
import Input from "../../../components/base/Input/Index";
import { Controller, useForm } from "react-hook-form";
import { Select } from "../../../components/base/Select";
import { Modal } from "../../../components/base/Modal";
import { Client } from "../../../types";
import { Loading } from "semente-js";
import { ClientContext } from "src/contexts/ClientContext";
import cep from "cep-promise";
import { States } from "src/pages/CompanySignUp/Forms/Company/consts";
import { toast } from "react-toastify";
import { ClientTypes, validateCNPJ } from "../../../common";
import * as yup from "yup";
import useYupValidationResolver from "src/hooks/useYupValidationResolver";
import { useDebouncedEffect } from "src/hooks/useDebouncedEffect";

const validationSchema = yup.object({
  cnpj: yup.string().required().length(14),
  name: yup.string().required(),
  type: yup.string().required(),
});

export const CreateClientModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const resolver = useYupValidationResolver(validationSchema);
  const { handleCreateClient } = useContext(ClientContext);
  const [isLoading, setIsLoading] = useState(false);
  const {
    control,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    setError,
    formState: { isSubmitSuccessful },
  } = useForm({
    defaultValues: {} as Client,
    resolver,
  });

  useEffect(() => {
    reset({});
  }, [isOpen, reset]);

  const handleSubmitClient = async (newClient: Client) => {
    const validCnpj = validateCNPJ(newClient.cnpj);

    if (!validCnpj) {
      toast.error("CNPJ inválido.");
      setError("cnpj", new Error("cnpj inválido"));
      return;
    }

    if (!newClient.address.state_province) {
      toast.error("Cep inválido.");
      setError("address.zip", new Error("Cep inválido"));
      return;
    }

    setIsLoading(true);
    await handleCreateClient(newClient);
    onClose();
    setIsLoading(false);
  };

  const stateWatcher = watch("address.state_province");
  const zipWatcher = watch("address.zip");

  const handleClose = () => {
    reset({});
    onClose();
  };

  useDebouncedEffect(() => onCepFieldChange(zipWatcher), [zipWatcher], 800);

  const onCepFieldChange = async (zip: string) => {
    setValue("address.zip", zip);
    const values = getValues();

    try {
      const addressInfo = await cep(values.address.zip.replace("-", ""));
      reset({
        ...values,
        address: {
          zip: values.address.zip,
          city: addressInfo.city,
          state_province: addressInfo.state,
          address: addressInfo.street,
          country: "BR",
        },
      });
    } catch (err) {
      setValue("address.state_province", "");
      setError("address.zip", new Error("Cep inválido"));
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title={isSubmitSuccessful ? "" : "Criar cliente"}
    >
      {isLoading ? (
        <Center minH={"300px"}>
          <Loading
            height="100"
            width="100"
            color="#408EC5"
            secondaryColor="#408EC5"
            radius="12.5"
            ariaLabel="mutating-dots-loading"
            visible={true}
          />
        </Center>
      ) : (
        <>
          <VStack
            spacing={"16px"}
            w={"100%"}
            alignItems={"flex-start"}
            px={"24px"}
            borderTop={"1px solid"}
            borderBottom={"1px solid"}
            borderColor={"Gray.$300"}
            py={"24px"}
          >
            <Controller
              name={"name"}
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState: { error } }) => (
                <Input {...field} isInvalid={!!error} placeholder={"Nome"} />
              )}
            />

            <Controller
              name={"cnpj"}
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState: { error } }) => (
                <Input
                  {...field}
                  isInvalid={!!error}
                  variant={"cnpj"}
                  placeholder={"CNPJ"}
                />
              )}
            />

            <Controller
              name={"type"}
              control={control}
              rules={{ required: true }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <Box w={"100%"}>
                  <Select
                    items={ClientTypes}
                    placeholder={"Tipo"}
                    onChange={(e) => onChange(e.value)}
                    {...(value && {
                      value: {
                        label: value,
                        value: value,
                      },
                    })}
                    isInvalid={!!error}
                  />
                </Box>
              )}
            />

            <HStack alignItems={"center"}>
              <Icon name={"building"} color={"#576975"} size={"22px"} />
              <Text fontSize={"L_md"} color={"Gray.$700"} ml={"10px"}>
                Endereço
              </Text>
            </HStack>

            <Controller
              name={"address.zip"}
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState: { error } }) => (
                <Input
                  {...field}
                  onChange={field.onChange}
                  isInvalid={!!error}
                  variant={"cep"}
                  placeholder={"CEP"}
                />
              )}
            />

            {stateWatcher && (
              <>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name={"address.address"}
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      {...field}
                      isInvalid={!!error}
                      placeholder={"Endereço"}
                    />
                  )}
                />

                <Controller
                  rules={{ required: true }}
                  control={control}
                  name={"address.number"}
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      {...field}
                      isInvalid={!!error}
                      type={"number"}
                      placeholder={"Número"}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name={"address.complement"}
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      {...field}
                      isInvalid={!!error}
                      placeholder={"Complemento (opcional)"}
                    />
                  )}
                />

                <Controller
                  rules={{ required: true }}
                  control={control}
                  name={"address.city"}
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      {...field}
                      isInvalid={!!error}
                      placeholder={"Cidade"}
                    />
                  )}
                />

                <Controller
                  rules={{ required: true }}
                  control={control}
                  name={"address.state_province"}
                  render={({ field, fieldState: { error } }) => (
                    <Box w={"100%"}>
                      <Select
                        placeholder="Estado"
                        items={States}
                        value={{
                          value: field.value,
                          label:
                            States.find((e) => e.value === field.value)
                              ?.label || "",
                        }}
                        onChange={(e) => field.onChange(e.value)}
                      />
                    </Box>
                  )}
                />
              </>
            )}
          </VStack>

          <Button
            m={"24px !important"}
            variant={"Primary"}
            minH={"48px !important"}
            onClick={handleSubmit(handleSubmitClient)}
          >
            Criar cliente
          </Button>
        </>
      )}
    </Modal>
  );
};
