import { Center, Image, Text, VStack } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { searchSuperIcon } from "src/assets/images";
import { Props } from "../types";
import { Consultancy } from "src/types";
import { toast } from "react-toastify";
import { Loading } from "semente-js";
import ConsultancyCard from "./Card";
import ConsultancyService from "src/services/consultancy";

const ConsultanciesSection: React.FC<Props> = ({ task }) => {
  const [consultancy, setConsultancy] = useState({
    data: [] as Consultancy[],
    isLoading: true,
  });

  useEffect(() => {
    try {
      ConsultancyService.getByTaskId(task.id).then((data) =>
        setConsultancy({ isLoading: false, data })
      );
    } catch (err) {
      toast.error("Ocorreu um erro ao buscar as consultorias");
    }
  }, [task.id]);

  return (
    <motion.div
      initial={{ scale: 0.9 }}
      animate={{ scale: 1 }}
      style={{ width: "100%", height: "100%", paddingBottom: "32px" }}
    >
      <VStack
        w={"100%"}
        h={"100%"}
        justifyContent={"space-between"}
        pb={"64px"}
      >
        <VStack w={"100%"} flex={1} px={"24px"} alignItems={"flex-start"}>
          <Text fontWeight={600} fontSize={"RH_sm"} fontFamily={"Raleway"}>
            Visão Geral
          </Text>

          {consultancy.isLoading ? (
            <Center flex={1} w={"100%"}>
              <Loading
                height="100"
                width="100"
                color="#408EC5"
                secondaryColor="#408EC5"
                radius="12.5"
                ariaLabel="mutating-dots-loading"
                visible={true}
              />
            </Center>
          ) : (
            <VStack w={"100%"} h={"100%"} m={"0px !important"}>
              {consultancy.data?.length >= 1 ? (
                <VStack mt={"32px !important"} position={"relative"} w={"100%"}>
                  {consultancy.data
                    .sort((a, b) => a.column_index - b.column_index)
                    .map((consultancy) => (
                      <ConsultancyCard
                        consultancy={consultancy}
                        key={consultancy.id}
                      />
                    ))}
                </VStack>
              ) : (
                <Center w={"100%"} h={"100%"}>
                  <VStack maxW={"370px"} spacing={0}>
                    <Image
                      alt={"Ícone de uma lupa"}
                      src={searchSuperIcon}
                      width={"52px"}
                      h={"64px"}
                    />
                    <Text
                      fontWeight={600}
                      fontFamily={"Raleway"}
                      fontSize={"RH_sm"}
                      textAlign={"center"}
                    >
                      Nenhuma consultoria
                    </Text>
                    <Text
                      textAlign={"center"}
                      fontWeight={400}
                      color={"Gray.$700"}
                      fontSize={"L_sm"}
                    >
                      Realize novas consultorias e elas aparecerão aqui.
                    </Text>
                  </VStack>
                </Center>
              )}
            </VStack>
          )}
        </VStack>
      </VStack>
    </motion.div>
  );
};

export default ConsultanciesSection;
