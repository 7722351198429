import React, { useContext, useState } from "react";
import { motion } from "framer-motion";
import { Props } from "../types";
import { fadeInUp, stagger } from "src/animations";
import { Center, HStack, Text } from "@chakra-ui/react";
import BoardCard from "./BoardCard";
import { BoardEmptyState } from "../Home/BoardEmptyState";
import { BoardCreation } from "../../BoardCreation";
import { KanbanContext } from "src/contexts/KanbanContext";
import { KanbanPage } from "src/pages/Kanban";
import { Loading } from "semente-js";
import { Button } from "semente-js";

const BoardsTabProject: React.FC<Props> = ({ project }) => {
  const [showBoardCreation, setShowBoardCreation] = useState(false);
  const { kanbanData } = useContext(KanbanContext);

  return kanbanData?.isLoading && !!kanbanData?.kanban_id ? (
    <Center h={"100%"} w={"100%"}>
      <Loading
        height="100"
        width="100"
        color="#408EC5"
        secondaryColor="#408EC5"
        radius="12.5"
        ariaLabel="mutating-dots-loading"
        visible={true}
      />
    </Center>
  ) : (
    <>
      {kanbanData.data ? (
        <KanbanPage />
      ) : (
        <motion.div
          style={{
            flex: 1,
            display: "flex",
            alignItems: "flex-start",
            width: "100%",
            flexDirection: "column",
          }}
          exit={{ opacity: 0 }}
          initial="initial"
          animate="animate"
        >
          <HStack w={"100%"}>
            <Text
              fontSize={"RH_lg"}
              fontFamily={"raleway"}
              color={"Gray.$800"}
              fontWeight={500}
              flex={1}
            >
              Quadros de consultoria
            </Text>

            {project.boards.length >= 1 && (
              <Button
                iconName="plus"
                label="Novo quadro"
                onClick={() => setShowBoardCreation(true)}
                color="gray"
                layout="rounded"
              />
            )}
          </HStack>
          {project.boards.length >= 1 ? (
            <motion.div
              variants={stagger}
              style={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gap: "16px",
                marginTop: "36px",
              }}
            >
              {project.boards.map((board) => (
                <motion.div
                  style={{ width: "100%" }}
                  key={board.id}
                  variants={fadeInUp}
                >
                  <BoardCard {...board} />
                </motion.div>
              ))}
            </motion.div>
          ) : (
            <Center w={"100%"}>
              <BoardEmptyState
                handleCreate={() => setShowBoardCreation(true)}
              />
            </Center>
          )}

          <BoardCreation
            isOpen={showBoardCreation}
            onClose={() => setShowBoardCreation(false)}
          />
        </motion.div>
      )}
    </>
  );
};

export default BoardsTabProject;
