import React, { useState } from "react";
import { Props } from "./types";
import { Button, Center, HStack, Text, VStack } from "@chakra-ui/react";
import { Icon } from "semente-js";
import IndicatorForm from "src/pages/Settings/Tabs/Indicator/Form";
import { Indicator } from "src/types";
import { SubmitHandler } from "react-hook-form";
import { toast } from "react-toastify";
import IndicatorService from "src/services/indicator";
import { Loading } from "semente-js";
import Checkbox from "src/components/Checkbox";

const ChoiceIndicatorsDialog: React.FC<Props> = ({
  handleInsertIndicator,
  defaultValues,
  handleSubmit,
  submitLabel,
  indicators,
}) => {
  const [indicatorsSelected, setIndicatorsSelected] = useState<string[]>(
    defaultValues || []
  );
  const [indicatorFormData, setIndicatorFormData] = useState<{}>();
  const [isLoading, setIsLoading] = useState(false);

  const handleCreateIndicator: SubmitHandler<Indicator> = async (values) => {
    try {
      setIsLoading(true);
      handleInsertIndicator(await IndicatorService.create(values));
      setIndicatorFormData(undefined);
      setIsLoading(false);
    } catch (err) {
      toast.error("Ocorreu um erro ao criar este indicador");
    }
  };

  return (
    <VStack flex={1}>
      {isLoading ? (
        <Center minH={"300px"}>
          <Loading
            height="100"
            width="100"
            color="#408EC5"
            secondaryColor="#408EC5"
            radius="12.5"
            ariaLabel="mutating-dots-loading"
            visible={true}
          />
        </Center>
      ) : (
        <>
          <VStack
            w={"100%"}
            borderTop={"1px solid"}
            borderColor={"Gray.$300"}
            maxH={"80vh"}
          >
            <VStack p={"24px"} w={"100%"} overflow={"auto"}>
              <HStack
                p={"18px 16px"}
                w={"100%"}
                borderRadius={"12px"}
                border={"1px solid"}
                borderColor={"Gray.$300"}
                spacing={"26px"}
                cursor={"pointer"}
                onClick={() => setIndicatorFormData({})}
              >
                <Icon name={"plus"} size={"20px"} />
                <Text fontSize={"LMD"} fontWeight={600}>
                  Novo indicador
                </Text>
              </HStack>

              <HStack w={"100%"} justifyContent={"space-between"}>
                <Text fontSize={"LMD"} fontWeight={600}>
                  Indicadores cadastrados
                </Text>

                <Text
                  py={"16px"}
                  fontSize={"LSM"}
                  fontWeight={400}
                  color={"Gray.$700"}
                >
                  {indicatorsSelected.length} indicadores selecionados
                </Text>
              </HStack>

              <VStack w={"100%"}>
                {indicators.map((indicator) => (
                  <HStack
                    cursor={"pointer"}
                    key={indicator.id}
                    w={"100%"}
                    py={"16px"}
                    onClick={() => {
                      if (defaultValues?.includes(indicator.id)) return;
                      if (indicatorsSelected.includes(indicator.id)) {
                        setIndicatorsSelected(
                          indicatorsSelected.filter((i) => i !== indicator.id)
                        );

                        return;
                      }
                      setIndicatorsSelected([
                        ...indicatorsSelected,
                        indicator.id,
                      ]);
                    }}
                  >
                    <HStack w={"100%"} justifyContent={"space-between"}>
                      <Text
                        fontSize={"LMD"}
                        fontWeight={600}
                        color={"Gray.$800"}
                      >
                        {indicator.name}
                      </Text>

                      <Checkbox
                        isDisabled={defaultValues?.includes(indicator.id)}
                        isChecked={indicatorsSelected.includes(indicator.id)}
                      />
                    </HStack>
                  </HStack>
                ))}
              </VStack>
            </VStack>

            <HStack
              w={"100%"}
              p={"16px 24px"}
              justifyContent={"space-between"}
              borderTop={"1px solid"}
              borderColor={"Gray.$300"}
            >
              <Button
                variant={"Primary"}
                minH={"48px !important"}
                borderRadius={"8px"}
                w={"100%"}
                onClick={() =>
                  handleSubmit(
                    indicatorsSelected.filter(
                      (indicator) => !defaultValues?.includes(indicator)
                    )
                  )
                }
              >
                {submitLabel || "Criar projeto"}
              </Button>
            </HStack>
          </VStack>

          <IndicatorForm
            indicator={indicatorFormData as Indicator}
            handleClose={() => setIndicatorFormData(undefined)}
            handleSubmitIndicator={handleCreateIndicator}
          />
        </>
      )}
    </VStack>
  );
};

export default ChoiceIndicatorsDialog;
