import { Center, Text, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Loading } from "semente-js";
import { useNavigate, useParams } from "react-router-dom";
import useDeviceDetector from "src/hooks/useDeviceDetector";
import InviteService from "src/services/invite";
import { Company, CompanyWithProfile, User } from "src/types";
import CompanyForm from "./Forms/Company";
import CompanyProfile from "./Forms/CompanyProfile";
import FinishedPage from "./Forms/FinishedPage";
import UserForm from "./Forms/User";

const defaultValues = {} as User & { company: CompanyWithProfile };

const errors = {
  ProjectNotFound: (
    <VStack>
      <Text
        fontFamily={"raleway"}
        fontSize={"RH_md"}
        fontWeight={600}
        color={"black"}
      >
        Oops, o projeto no qual você foi convidado não está mais disponível.
      </Text>
      <Text
        fontSize={"L_md"}
        fontWeight={400}
        color={"Gray.$700"}
        mt={"24px !important"}
      >
        Tente entrar em contato com o gestor de carteira responsável.
      </Text>
    </VStack>
  ),
  HashNotFound: (
    <VStack>
      <Text
        fontFamily={"raleway"}
        fontSize={"RH_md"}
        fontWeight={600}
        color={"black"}
      >
        Oops, aparentemente seu convite não é válido ou já foi utilizado.
      </Text>
      <Text
        fontSize={"L_md"}
        fontWeight={400}
        color={"Gray.$700"}
        mt={"24px !important"}
      >
        Tente entrar em contato com o gestor de carteira responsável.
      </Text>
    </VStack>
  ),
  HashToConsultant: (
    <VStack>
      <Text
        fontFamily={"raleway"}
        fontSize={"RH_md"}
        fontWeight={600}
        color={"black"}
      >
        Oops, este convite foi criado para um consultor.
      </Text>
      <Text
        fontSize={"L_md"}
        fontWeight={400}
        color={"Gray.$700"}
        mt={"24px !important"}
      >
        Esta página existe unicamente para criação de empresas, tente
      </Text>
    </VStack>
  ),
};

const CompanySignUp: React.FC = () => {
  const [ErrorComponent, setErrorComponent] = useState<JSX.Element>();
  const [FormComponent, setFormComponent] = useState<JSX.Element>(<></>);
  const { reset, getValues } = useForm({
    defaultValues,
  });
  const [isLoading, setIsLoading] = useState(true);
  const { isMobile } = useDeviceDetector();
  const navigate = useNavigate();
  const { hash } = useParams();

  const handleSubmitCompany = async (company: Company) => {
    reset({ ...getValues(), company: company });

    setFormComponent(
      <CompanyProfile
        defaultValues={getValues()}
        handleSubmitForm={() => setFormComponent(<FinishedPage />)}
      />
    );
  };

  const handleSubmitUser = async (user: User) => {
    reset({ ...getValues(), ...user });
    setFormComponent(
      <CompanyForm
        defaultValues={getValues()}
        handleSubmitForm={handleSubmitCompany}
      />
    );
  };

  useEffect(() => {
    (async () => {
      if (hash) {
        try {
          const data = await InviteService.findByHash(hash);

          if (!data.company_id) {
            setErrorComponent(errors["HashToConsultant"]);
            return;
          }

          reset({
            email: data.email,
            company: {
              ...data.company,
              name: data.name,
              id: data.company_id,
            },
          });

          if (data?.company && data?.company?.users_company_datas.length < 1) {
            setFormComponent(
              <UserForm
                defaultValues={getValues()}
                handleSubmitForm={handleSubmitUser}
              />
            );
          } else {
            reset({
              ...getValues(),
              id: data.company?.users_company_datas[0].user_id,
            });
            if (data.company?.status === "pending") {
              handleSubmitCompany(getValues().company);
            } else {
              handleSubmitUser(getValues());
            }
          }
        } catch (err: any) {
          if (err.toString().includes("403")) {
            setErrorComponent(errors["HashNotFound"]);
          } else {
            setErrorComponent(errors["ProjectNotFound"]);
          }
        }
        setIsLoading(false);
      } else {
        navigate("/");
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash, navigate, reset, getValues]);

  const Wrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return isMobile ? (
      <VStack flex={1} p={"16px"} h={"100vh"}>
        {children}
      </VStack>
    ) : (
      <Center background={"#FAFAFA"} h={"100%"} height={"100vh"}>
        <VStack
          overflow={"auto"}
          background={"white"}
          boxShadow={"0px 0px 10px rgba(29, 34, 31, 0.1)"}
          w={"448px"}
          borderRadius={"16px"}
          h={"90vh"}
          p={"16px"}
        >
          {children}
        </VStack>
      </Center>
    );
  };

  return isLoading ? (
    <Center flex={1} h={"100vh"}>
      <Loading
        height="100"
        width="100"
        color="#408EC5"
        secondaryColor="#408EC5"
        radius="12.5"
        ariaLabel="mutating-dots-loading"
        visible={true}
      />
    </Center>
  ) : (
    <>
      {ErrorComponent ? (
        <Center flex={1} h={"100vh"}>
          {ErrorComponent}
        </Center>
      ) : (
        <Wrapper>{FormComponent}</Wrapper>
      )}
    </>
  );
};

export default CompanySignUp;
