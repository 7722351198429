import React, { useEffect, useState } from "react";
import { DefaultProfileValue, FormProps } from "./types";
import { Modal } from "src/components/base/Modal";
import {
  Button,
  Center,
  HStack,
  Image,
  Radio,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Loading } from "semente-js";
import { toast } from "react-toastify";
import CompanyService from "src/services/company";
import Checkbox from "src/components/Checkbox";

const CompanyProfileForm: React.FC<FormProps> = ({
  props,
  handleClose,
  company_id,
  handleUpdateCompany,
}) => {
  const [optionsSelected, setOptionsSelected] = useState<string[]>(
    props?.selecteds || []
  );

  useEffect(() => {
    setOptionsSelected(props?.selecteds || []);
  }, [props?.selecteds]);

  const [profileData, setProfileData] = useState({
    data: [] as DefaultProfileValue[],
    isLoading: true,
  });

  useEffect(() => {
    if (props) {
      setProfileData({ data: [] as DefaultProfileValue[], isLoading: true });
      props.service
        .getAll()
        .then((data) => setProfileData({ data, isLoading: false }));
    }
  }, [props]);

  const handleUpdateProfile = async () => {
    try {
      setProfileData({ ...profileData, isLoading: true });
      const toRemove = props.selecteds.reduce((acc, item) => {
        if (!optionsSelected.includes(item)) {
          return [...acc, item];
        }

        return acc;
      }, [] as string[]);

      const toAdd = optionsSelected.reduce((acc, item) => {
        if (!props.selecteds.includes(item)) {
          return [...acc, item];
        }

        return acc;
      }, [] as string[]);
      const values = { toRemove, toAdd };

      await Promise.all([
        ...[await props.service.create(values.toAdd, company_id)],
        ...[
          props.service.remove
            ? values.toRemove.map(
                async (company_profile_id) =>
                  await (props?.service as any)?.remove({
                    company_profile_id,
                    company_id,
                  })
              )
            : [],
        ],
      ]);

      handleUpdateCompany(await CompanyService.getCompanyById(company_id));
      handleClose();
    } catch (err) {
      toast.error("Ocorreu um erro ao atualizar o perfil");
    }
    setProfileData({ ...profileData, isLoading: false });
  };

  const handleSelectOption = (option: string) => {
    if (optionsSelected.includes(option)) {
      setOptionsSelected(optionsSelected.filter((opt) => opt !== option));
    } else {
      setOptionsSelected([
        ...(props.isMultiple ? optionsSelected : []),
        option,
      ]);
    }
  };

  return (
    <Modal isOpen={!!props} onClose={handleClose}>
      {!props || profileData.isLoading ? (
        <Center py={"64px !important"}>
          <Loading
            height="100"
            width="100"
            color="#408EC5"
            secondaryColor="#408EC5"
            radius="12.5"
            ariaLabel="mutating-dots-loading"
            visible={true}
          />
        </Center>
      ) : (
        <VStack py={"12px"} maxW={"568px"} h={"80vh"}>
          <VStack px={"24px"} w={"100%"} alignItems={"flex-start"}>
            <Text fontFamily={"Raleway"} fontSize={"RH_sm"} fontWeight={600}>
              {props.title}
            </Text>

            <Text
              fontFamily={"Raleway"}
              fontSize={"L_md"}
              fontWeight={500}
              color={"Gray.$600"}
            >
              {props.subtitle}
            </Text>
          </VStack>

          <VStack
            px={"24px"}
            mt={"32px"}
            w={"100%"}
            alignItems={"flex-start"}
            overflow={"auto"}
          >
            {profileData.data.map((profile, index) => (
              <HStack
                w={"100%"}
                key={profile.id}
                cursor={"pointer"}
                py={"16px"}
                borderBottom={"1px solid"}
                onClick={() => handleSelectOption(profile.id)}
                borderColor={
                  profileData.data.length - 1 === index
                    ? "transparent"
                    : "Gray.$400"
                }
              >
                {profile.image_url && (
                  <Image
                    src={profile.image_url}
                    mr={"16px"}
                    alt={`Image from ${profile.name}`}
                  />
                )}
                <VStack w={"100%"} alignItems={"flex-start"} spacing={0}>
                  <Text fontSize={"LMD"} fontWeight={500}>
                    {profile.name}
                  </Text>

                  {profile?.description && (
                    <Text fontSize={"LSM"} fontWeight={400} color={"Gray.$600"}>
                      {profile?.description}
                    </Text>
                  )}
                </VStack>

                {props.isMultiple ? (
                  <Checkbox isChecked={optionsSelected.includes(profile.id)} />
                ) : (
                  <Radio
                    size={"lg"}
                    isChecked={optionsSelected.includes(profile.id)}
                  />
                )}
              </HStack>
            ))}
          </VStack>

          <Center
            py={"16px"}
            px={"24px"}
            w={"100%"}
            borderTop={"1px solid"}
            borderColor={"Gray.$400"}
          >
            <Button
              onClick={handleUpdateProfile}
              borderRadius={"8px"}
              w={"100%"}
              variant={"Primary"}
            >
              Salvar
            </Button>
          </Center>
        </VStack>
      )}
    </Modal>
  );
};

export default CompanyProfileForm;
