import { Reorder } from "framer-motion";
import { Props } from "./types";
import React, { useRef, useState } from "react";
import {
  Center,
  HStack,
  Stack,
  Text,
  useOutsideClick,
  VStack,
} from "@chakra-ui/react";
import Icon from "src/components/base/Icon";
import { Loading } from "semente-js";

const QuestionItem: React.FC<Props> = ({
  handleDuplicate,
  menuOptions,
  question,
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const menuRef = useRef<any>(null);

  useOutsideClick({
    ref: menuRef,
    handler: () => setMenuIsOpen(false),
  });

  return (
    <Reorder.Item style={{ width: "100%" }} value={question.index}>
      <HStack
        p={"16px"}
        w={"100%"}
        border={"1px solid"}
        borderColor={"Gray.$400"}
        borderRadius={"12px"}
        cursor={
          question.isCreating || question.isRemoving
            ? "not-allowed"
            : "grabbing"
        }
        justifyContent={"space-between"}
        position={"relative"}
      >
        {(question.isCreating || question.isRemoving) && (
          <Center
            w={"96%"}
            h={"96%"}
            background={"white"}
            position={"absolute"}
            zIndex={"2"}
          >
            <Loading
              height="50"
              width="50"
              radius="9"
              color={question.isCreating ? "#408EC5" : "#DC1839"}
              ariaLabel="three-dots-loading"
              visible={true}
            />
          </Center>
        )}
        <HStack spacing={"18px"}>
          <Icon name={"file-alt-solid"} size={"24px"} />
          <Text>{question.title}</Text>
        </HStack>

        <HStack spacing={"8px"} position={"relative"}>
          <Center
            cursor={"pointer"}
            background={"Gray.$200"}
            borderRadius={"50%"}
            w={"36px"}
            h={"36px"}
            transition={"300ms all"}
            _hover={{ background: "Gray.$300" }}
            onClick={handleDuplicate}
          >
            <Icon name={"copy-solid"} />
          </Center>

          <Center position={"relative"}>
            <Center
              cursor={"pointer"}
              background={"Gray.$200"}
              borderRadius={"50%"}
              w={"36px"}
              h={"36px"}
              transition={"300ms all"}
              _hover={{ background: "Gray.$300" }}
              onClick={() => setMenuIsOpen(true)}
            >
              <Icon name={"ellipsis-h"} />
            </Center>
            {menuIsOpen && (
              <VStack
                py={"8px"}
                ref={menuRef}
                left={0}
                top={"40px"}
                position={"absolute"}
                zIndex={200}
                background={"Gray.$100"}
                minW={"240px"}
                borderRadius={"12px"}
                boxShadow={"0px 2px 16px rgba(0, 0, 0, 0.12)"}
              >
                {menuOptions.map((option) => (
                  <Stack
                    cursor={"pointer"}
                    key={option.title}
                    padding={"12px 16px"}
                    onClick={() => {
                      option.onClick(question);
                      setMenuIsOpen(false);
                    }}
                    w={"100%"}
                    alignItems={"flex-start"}
                    transition={"300ms all"}
                    _hover={{
                      background: "Gray.$200",
                    }}
                  >
                    <Text
                      fontSize={"L_sm"}
                      fontWeight={400}
                      color={option.color}
                    >
                      {option.title}
                    </Text>
                  </Stack>
                ))}
              </VStack>
            )}
          </Center>
        </HStack>
      </HStack>
    </Reorder.Item>
  );
};

export default QuestionItem;
