import {
  Box,
  Center,
  HStack,
  Image,
  Text,
  VStack,
  useTheme,
} from "@chakra-ui/react";
import React, { useContext, useMemo } from "react";
import { ProjectContext } from "src/contexts/ProjectContext";
import { Loading } from "semente-js";
import ProjectNavItem from "./NavItem";
import { ProjectFull } from "src/types";
import { createImageFromInitials } from "src/common";
import HomeTabProject from "./Tabs/Home";
import BoardsTabProject from "./Tabs/Boards";
import CompaniesTabProject from "./Tabs/Companies";
import ConsultantsTabProject from "./Tabs/Consultants";
import { KanbanContext } from "src/contexts/KanbanContext";
import SettingsTabProject from "./Tabs/Settings";
import { ProjectViewContext } from "./Context";
import { useNavigate } from "react-router-dom";
import Reports from "./Tabs/Reports";

export type INavItem = {
  label: string;
  icon?: string;
  isNew?: boolean;
  Component?: React.ReactNode;
  subItems?: INavItem[];
  onClick?: () => void;
  isLast?: boolean;
};

const ProjectView: React.FC = () => {
  const { currentProject } = useContext(ProjectContext);
  const { handleResetKanban, handleOut } = useContext(KanbanContext);
  const { currentTabIn, setCurrentTabIn } = useContext(ProjectViewContext);
  const theme = useTheme();
  const navigate = useNavigate();

  const NavItems: INavItem[] = [
    {
      label: "Início",
      icon: "home-alt-solid",
      Component: (
        <HomeTabProject project={currentProject.data as ProjectFull} />
      ),
    },
    {
      label: "Quadros",
      icon: "apps",
      Component: (
        <BoardsTabProject project={currentProject.data as ProjectFull} />
      ),
      /*      ...(kanbanData &&
        kanbanData.data &&
        !kanbanData.isLoading && {
          subItems: [
            {
              label: kanbanData?.data.name,
              Component: <></>,
            },
          ],
        }), */
    },
    {
      label: "Relatórios",
      icon: "analytics",
      isNew: true,
      Component: <Reports project={currentProject.data as ProjectFull} />,
    },
    {
      label: "Empresas",
      icon: "store-solid",
      Component: (
        <CompaniesTabProject project={currentProject.data as ProjectFull} />
      ),
    },
    {
      label: "Consultores",
      icon: "users-alt-solid",
      Component: (
        <ConsultantsTabProject project={currentProject.data as ProjectFull} />
      ),
    },
    {
      label: "Configurações",
      icon: "setting",
      Component: useMemo(
        () => (
          <SettingsTabProject project={currentProject.data as ProjectFull} />
        ),
        [currentProject.data]
      ),
      isLast: true,
    },
    {
      label: "Mudar projeto",
      onClick: () => {
        navigate("/");
        handleOut();
      },
      icon: "sync",
    },
  ];

  return currentProject.isLoading ? (
    <Center>
      <Loading
        height="100"
        width="100"
        color="#408EC5"
        secondaryColor="#408EC5"
        radius="12.5"
        ariaLabel="mutating-dots-loading"
        visible={true}
      />
    </Center>
  ) : currentProject.data ? (
    <HStack flex={1} spacing={0} h={"100%"}>
      <VStack
        py={"24px"}
        minW={"260px"}
        borderRight={"1px solid"}
        borderRightColor={"Gray.$300"}
        h={"100%"}
      >
        <HStack w={"100%"} spacing={"16px"} px={"16px"}>
          <Image
            src={
              currentProject.data.image_url ||
              createImageFromInitials({
                background: (theme.colors as any).Tertiary.pure,
                color: (theme.colors as any).white,
                name: currentProject?.data.name.substring(0, 2) || "",
                size: 36,
                fontSize: 18,
              })
            }
            alt={`Imagem do projeto: ${currentProject.data.name}`}
            w={"36px"}
            h={"36px"}
            borderRadius={"50%"}
            objectFit={"cover"}
          />

          <VStack flex={1} spacing={0} alignItems={"flex-start"}>
            <Text fontSize={"L_md"} color={"Gray.$800"} fontWeight={500}>
              {currentProject.data.name}
            </Text>

            <Text fontSize={"L_sm"} color={"Gray.$700"} fontWeight={400}>
              Projeto
            </Text>
          </VStack>
        </HStack>

        <VStack
          flex={1}
          mt={"36px !important"}
          w={"100%"}
          spacing={0}
          justifyContent={"space-between"}
          h={"100%"}
        >
          <VStack w={"100%"} h={"100%"}>
            {NavItems.map((item, key) => {
              const isChecked = currentTabIn === key;

              return (
                <VStack
                  w={"100%"}
                  key={key}
                  mb={
                    item.subItems && item.subItems?.length >= 1
                      ? "36px !important"
                      : ""
                  }
                  spacing={0}
                  marginTop={item.isLast ? "auto !important" : "inherit"}
                >
                  <ProjectNavItem
                    handleSelectItem={() => {
                      if (item.onClick) {
                        item.onClick();
                        return;
                      }
                      setCurrentTabIn(key);
                      handleResetKanban();
                    }}
                    item={item}
                    isChecked={
                      isChecked && (!item.subItems || item.subItems.length < 1)
                    }
                  />

                  <VStack w={"100%"} transition={"300ms all"}>
                    {item.subItems?.map((subItem, index) => (
                      <HStack
                        key={key + index}
                        position={"relative"}
                        pl={"24px"}
                        w={"100%"}
                        spacing={"24px"}
                        background={
                          isChecked ? "Tertiary.background" : "transparent"
                        }
                      >
                        <Box
                          h={"100%"}
                          w={"2px"}
                          background={"Tertiary.dark"}
                        />
                        <HStack w={"100%"} py={"12px"}>
                          <Text
                            fontSize={"L_md"}
                            color={isChecked ? "Tertiary.dark" : "Gray.$800"}
                            fontWeight={500}
                          >
                            {subItem.label}
                          </Text>
                        </HStack>
                      </HStack>
                    ))}
                  </VStack>
                </VStack>
              );
            })}
          </VStack>
        </VStack>
      </VStack>

      <Center
        w={"100%"}
        h={"calc(100vh - 80px)"}
        p={"32px"}
        overflow={"auto"}
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
      >
        {NavItems[currentTabIn].Component}
      </Center>
    </HStack>
  ) : (
    <Center h={"100%"}>
      <Text>Ocorreu uma falha ao buscar seu projeto.</Text>
    </Center>
  );
};

export default ProjectView;
