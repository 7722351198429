import React, { useContext, useEffect, useState } from "react";
import { Props } from "./types";
import { ClientContext } from "src/contexts/ClientContext";
import {
  Box,
  Button,
  Center,
  HStack,
  Image,
  Text,
  VStack,
  useTheme,
} from "@chakra-ui/react";
import { Loading } from "semente-js";
import { Client } from "src/types";
import Icon from "src/components/base/Icon";
import { createImageFromInitials } from "src/common";
import { masks } from "src/components/base/Input/masks";
import { CreateClientModal } from "src/pages/Clients/CreateClientModal";
import Checkbox from "src/components/Checkbox";

const ChoiceClientsDialog: React.FC<Props> = ({
  handleSubmit,
  isProjectDialog,
  defaultValues,
}) => {
  const { clients, refreshClients } = useContext(ClientContext);
  const [clientsSelected, setClientsSelected] = useState<Client["id"][]>([]);
  const [showCreateClient, setShowCreateClient] = useState(false);
  const theme = useTheme<any>();

  useEffect(() => {
    refreshClients(false);
  }, [refreshClients]);

  return (
    <VStack>
      {clients.isLoading ? (
        <Center minH={"300px"}>
          <Loading
            height="100"
            width="100"
            color="#408EC5"
            secondaryColor="#408EC5"
            radius="12.5"
            ariaLabel="mutating-dots-loading"
            visible={true}
          />
        </Center>
      ) : (
        <>
          {clients.data && (
            <VStack
              h={"100%"}
              w={"100%"}
              borderTop={"1px solid"}
              borderColor={"Gray.$300"}
              maxH={"80vh"}
            >
              <VStack p={"24px"} w={"100%"} h={"100%"} overflow={"auto"}>
                <HStack
                  p={"18px 16px"}
                  w={"100%"}
                  borderRadius={"12px"}
                  border={"1px solid"}
                  borderColor={"Gray.$300"}
                  spacing={"26px"}
                  cursor={"pointer"}
                  onClick={() => setShowCreateClient(true)}
                >
                  <Icon name={"plus"} size={"20px"} />
                  <Text fontSize={"LMD"} fontWeight={600}>
                    Novo cliente
                  </Text>
                </HStack>

                <HStack w={"100%"} justifyContent={"space-between"}>
                  <Text
                    fontSize={"LMD"}
                    fontWeight={600}
                    onClick={() => setClientsSelected([])}
                  >
                    Clientes cadastrados
                  </Text>

                  <Text
                    py={"16px"}
                    fontSize={"LSM"}
                    fontWeight={400}
                    color={"Gray.$700"}
                  >
                    {(defaultValues?.length || 0) + clientsSelected.length}{" "}
                    clientes selecionados
                  </Text>
                </HStack>

                <VStack w={"100%"} h={"100%"}>
                  {clients.data?.map((client, index) => (
                    <HStack
                      cursor={"pointer"}
                      key={client.id}
                      w={"100%"}
                      py={"16px"}
                      onClick={() => {
                        if (!defaultValues?.includes(client.id)) {
                          if (clientsSelected.includes(client.id)) {
                            setClientsSelected(
                              clientsSelected.filter(
                                (value) => value !== client.id
                              )
                            );
                          } else {
                            setClientsSelected([...clientsSelected, client.id]);
                          }
                        }
                      }}
                    >
                      <Center
                        w="36px"
                        h="36px"
                        overflow="hidden"
                        borderRadius="60px"
                        alignItems="center"
                        background={"#E3E5E8"}
                        justifyContent="center"
                      >
                        <Image
                          src={createImageFromInitials({
                            background: theme.colors.Tertiary.pure,
                            color: theme.colors.white,
                            name: client?.name.substring(0, 1) || "",
                            size: 36,
                            fontSize: 20,
                          })}
                          width="100%"
                          height="100%"
                          borderRadius="60px"
                          objectFit={"cover"}
                        />
                      </Center>

                      <VStack flex={1} position={"relative"}>
                        <HStack w={"100%"} justifyContent={"space-between"}>
                          <VStack alignItems={"flex-start"} spacing={1}>
                            <Text
                              fontSize={"LMD"}
                              fontWeight={600}
                              color={"Gray.$800"}
                            >
                              {client.name}
                            </Text>
                            <Text
                              fontSize={"LSM"}
                              fontWeight={400}
                              color={"Gray.$700"}
                            >
                              {masks["cnpj"](client.cnpj)}
                            </Text>
                          </VStack>

                          <Checkbox
                            isDisabled={defaultValues?.includes(client.id)}
                            isChecked={clientsSelected.includes(client.id)}
                          />
                        </HStack>

                        {clients.data && clients.data?.length - 1 !== index && (
                          <Box
                            position={"absolute"}
                            w={"100%"}
                            bottom={"-18px"}
                            background={"Gray.$400"}
                            h={"1px"}
                          />
                        )}
                      </VStack>
                    </HStack>
                  ))}
                </VStack>
              </VStack>

              <HStack
                w={"100%"}
                p={"16px 24px"}
                justifyContent={"space-between"}
                borderTop={"1px solid"}
                borderColor={"Gray.$300"}
              >
                <Button
                  variant={"Primary"}
                  minH={"48px !important"}
                  borderRadius={"8px"}
                  w={"100%"}
                  isDisabled={clientsSelected.length < 1}
                  onClick={() => handleSubmit(clientsSelected)}
                >
                  {isProjectDialog ? "Continuar" : "Adicionar"}
                </Button>
              </HStack>
            </VStack>
          )}
        </>
      )}

      <CreateClientModal
        isOpen={showCreateClient}
        onClose={() => setShowCreateClient(false)}
      />
    </VStack>
  );
};

export default ChoiceClientsDialog;
