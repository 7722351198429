import { Button, Center, HStack, Text } from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import Icon from "src/components/base/Icon";
import { Props } from "./types";
import { Client, ClientData } from "src/types";
import { Modal } from "src/components/base/Modal";
import { ProjectContext } from "src/contexts/ProjectContext";
import { Loading } from "semente-js";
import ClientLine from "./ClientLine";
import ChoiceClientsDialog from "src/pages/Project/CreateProjectModal/ChoiceClientsDialog";
import { fadeInUp } from "src/animations";
import { motion } from "framer-motion";

const ClientsSection: React.FC<Props> = ({ clients, project }) => {
  const [showAddClient, setShowAddClient] = useState(false);
  const { handleInsertClients, handleRemoveClient } =
    useContext(ProjectContext);
  const [clientsIsLoading, setClientsIsLoading] = useState(false);

  const handleAddClient = async (clients: Client["id"][]) => {
    setClientsIsLoading(true);
    await handleInsertClients(clients, project);
    setShowAddClient(false);
    setClientsIsLoading(false);
  };

  const handleDeleteClient = async (client: ClientData) => {
    await handleRemoveClient(client.id, project);
  };

  return (
    <motion.div {...fadeInUp} style={{ width: "100%" }}>
      {clients.map((client, index) => (
        <ClientLine
          key={client.id}
          client={client.client}
          handleRemoveClient={() => handleDeleteClient(client)}
          isLast={clients.length - 1 === index}
        />
      ))}

      <Button
        minH={"36px"}
        mt={"16px !important"}
        onClick={() => setShowAddClient(true)}
      >
        <HStack spacing={"8px"}>
          <Icon name={"plus"} />
          <Text>Adicionar cliente</Text>
        </HStack>
      </Button>

      <Modal
        title={"Adicionar clientes"}
        isOpen={showAddClient}
        onClose={() => setShowAddClient(false)}
      >
        {clientsIsLoading ? (
          <Center minH={"300px"}>
            <Loading
              height="100"
              width="100"
              color="#408EC5"
              secondaryColor="#408EC5"
              radius="12.5"
              ariaLabel="mutating-dots-loading"
              visible={true}
            />
          </Center>
        ) : (
          <ChoiceClientsDialog
            handleSubmit={handleAddClient}
            defaultValues={clients.reduce(
              (acc, item) => [...acc, item.client_id],
              [] as string[]
            )}
          />
        )}
      </Modal>
    </motion.div>
  );
};

export default ClientsSection;
