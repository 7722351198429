import { Button, Center, Grid, HStack, Text, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Props } from "../types";
import { Loading } from "semente-js";
import { Note } from "src/types";
import RemoveConfirmation from "./RemoveConfirmation";
import NoteService from "src/services/note";
import { useProjectContext } from "src/contexts/ProjectContext";
import NoteBox from "./NoteBox";
import Icon from "src/components/base/Icon";
import NoteForm from "./Form";
import { SubmitHandler } from "react-hook-form";
import { toast } from "react-toastify";
import NoteVisualizer from "./NoteVisualizer";

const NotesSection: React.FC<Props> = ({ task }) => {
  const [noteToRemove, setNoteToRemove] = useState<Note>();
  const [noteToUpdate, setNoteToUpdate] = useState<Note>();
  const [noteToVisualizer, setNoteToVisualizer] = useState<Note>();
  const { currentProject } = useProjectContext();

  const [notes, setNotes] = useState({
    data: [] as Note[],
    isLoading: true,
  });

  useEffect(() => {
    if (currentProject.data) {
      NoteService.getByProjectAndCompany(
        currentProject.data?.id,
        task.company_id
      ).then((data) => setNotes({ isLoading: false, data }));
    }
  }, [task.company_id, currentProject]);

  const handleRemoveNote = async () => {
    if (noteToRemove) {
      try {
        await NoteService.remove(noteToRemove?.id);
        setNotes((p) => ({
          ...p,
          data: p.data.filter((note) => note.id !== noteToRemove.id),
        }));
        setNoteToRemove(undefined);
      } catch (_) {
        toast.error("Ocorreu um erro ao remover esta nota");
      }
    }
  };

  const handleSubmitNote: SubmitHandler<Note> = async (values) => {
    try {
      if ("id" in values) {
        await NoteService.update(values.id, values);
        setNotes((p) => ({
          ...p,
          data: p.data.map((note) => (note.id === values.id ? values : note)),
        }));
        setNoteToUpdate(undefined);
        return;
      }

      const newNote = await NoteService.create({
        ...(values as Note),
        project_id: currentProject.data?.id as string,
        company_id: task.company_id,
      });
      setNotes((p) => ({ ...p, data: [...p.data, newNote] }));
      setNoteToUpdate(undefined);
    } catch (_) {
      toast.error("Ocorreu um erro ao gerenciar sua nota");
    }
  };

  return (
    <motion.div
      initial={{ scale: 0.9 }}
      animate={{ scale: 1 }}
      style={{ width: "100%", height: "100%" }}
    >
      <VStack
        w={"100%"}
        h={"100%"}
        justifyContent={"space-between"}
        pb={"64px"}
      >
        <VStack
          w={"100%"}
          flex={1}
          px={"24px"}
          alignItems={"flex-start"}
          overflow={"auto"}
          spacing={0}
        >
          <HStack w={"100%"} mb={"24px "}>
            <Text
              flex={1}
              fontWeight={600}
              fontSize={"RH_sm"}
              fontFamily={"Raleway"}
            >
              Notas
            </Text>
          </HStack>

          {notes.isLoading ? (
            <Center w={"100%"} h={"100%"}>
              <Loading
                height="100"
                width="100"
                color="#408EC5"
                secondaryColor="#408EC5"
                radius="12.5"
                ariaLabel="mutating-dots-loading"
                visible={true}
              />
            </Center>
          ) : (
            <Grid gridTemplateColumns={"1fr 1fr 1fr"} w={"100%"} gap={"16px"}>
              {notes.data.length >= 1 &&
                notes.data.map((note) => (
                  <NoteBox
                    handleClick={() => setNoteToVisualizer(note)}
                    key={note.id}
                    {...note}
                  />
                ))}

              <Button
                onClick={() => setNoteToUpdate({} as Note)}
                borderRadius={"16px"}
                bg={"Gray.$200"}
                height={"168px"}
                w="100%"
              >
                <VStack gap={"8px"}>
                  <Icon name="plus" />
                  <span>Nova nota</span>
                </VStack>
              </Button>
            </Grid>
          )}
        </VStack>
      </VStack>

      {noteToUpdate && (
        <NoteForm
          note={noteToUpdate as Note}
          handleSubmitForm={handleSubmitNote}
          handleClose={() => setNoteToUpdate(undefined)}
        />
      )}

      <NoteVisualizer
        note={noteToVisualizer}
        handleRemove={() => {
          setNoteToVisualizer(undefined);
          setNoteToRemove(noteToVisualizer);
        }}
        handleUpdate={() => {
          setNoteToVisualizer(undefined);
          setNoteToUpdate(noteToVisualizer);
        }}
        handleClose={() => setNoteToVisualizer(undefined)}
      />

      <RemoveConfirmation
        note={noteToRemove}
        handleConfirm={handleRemoveNote}
        handleClose={() => setNoteToRemove(undefined)}
      />
    </motion.div>
  );
};

export default NotesSection;
