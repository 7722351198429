import { Center, HStack, Text, VStack } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { useState, useContext, useEffect } from "react";
import { Loading } from "semente-js";
import { Project } from "../../types";
import { ClientsList } from "./List";
import { AddButton } from "../../components/AddButton";
import { HomeEmptyState } from "src/components/HomeEmptyState";
import { ClientContext } from "src/contexts/ClientContext";
import { CreateClientModal } from "./CreateClientModal";
import { ProtectedComponent } from "semente-js";
import { PERMISSIONS } from "src/permissions";

export const Clients: React.FC = () => {
  const [createIsOpen, setCreateIsOpen] = useState<Project | {}>();
  const { clients, refreshClients } = useContext(ClientContext);

  useEffect(() => {
    refreshClients(true);
  }, [refreshClients]);

  return (
    <VStack w={"100%"} px="80px" py="24px">
      <HStack
        w={"100%"}
        pb={"24px"}
        alignItems={"flex-end"}
        justifyContent={"space-between"}
        borderBottom={"1px solid"}
        borderColor={"Gray.$400"}
      >
        <Text
          fontSize={"RD_md"}
          color={"Gray.$800"}
          fontFamily={"raleway"}
          fontWeight={600}
        >
          Clientes
        </Text>
        <ProtectedComponent
          requiredClientRoles={[PERMISSIONS.CLIENT_CREATION]}
          children={
            <AddButton
              label={"Criar novo cliente"}
              onClick={() => setCreateIsOpen({})}
            />
          }
        />
      </HStack>
      {clients.isLoading ? (
        <Center mt={"64px !important"}>
          <Loading
            height="100"
            width="100"
            color="#408EC5"
            secondaryColor="#408EC5"
            radius="12.5"
            ariaLabel="mutating-dots-loading"
            visible={true}
          />
        </Center>
      ) : (
        <motion.div
          initial={{ scale: 0.8 }}
          animate={{ scale: 1 }}
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {clients.data && clients.data?.length >= 1 ? (
            <ClientsList />
          ) : (
            <HomeEmptyState
              title="Nenhum cliente aqui ainda"
              description="Comece criando um cliente para utilizar em projetos!"
              buttonLabel="Criar novo cliente"
              handleCreate={() => setCreateIsOpen({})}
            />
          )}
        </motion.div>
      )}
      <CreateClientModal
        isOpen={!!createIsOpen}
        onClose={() => setCreateIsOpen(undefined)}
      />
    </VStack>
  );
};
