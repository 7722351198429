import { Center, HStack, Text, VStack } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { Company, CompanyWithProfile } from "../../../types";
import DetailTab from "./Tabs/Detail";
import TabNavigator from "src/components/TabNavigator";
import { toast } from "react-toastify";
import CompanyService from "src/services/company";
import { UserContext } from "src/contexts/UserContext";
import { useNavigate, useParams } from "react-router-dom";
import { Loading } from "semente-js";
import ProfileDetails from "./Tabs/Profile";

const CompanyForm: React.FC = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [company, setCompany] = useState({
    data: {} as CompanyWithProfile,
    isLoading: true,
  });
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      CompanyService.getCompanyById(id)
        .then((data) => setCompany({ data, isLoading: false }))
        .catch(() => toast.error("Ocorreu um erro ao buscar essa empresa"));
    }
  }, [id]);

  const handleUpdate = async (newCompany: Partial<Company>) => {
    try {
      if (user) {
        await CompanyService.update(
          { ...newCompany, status: "finished" },
          user.id
        );
        setCompany({
          isLoading: false,
          data: { ...company.data, ...newCompany, status: "finished" },
        });
        toast.success("Empresa atualizada com sucesso!");
      }
    } catch (err) {
      toast.error("Ocorreu um erro ao atualizar a empresa.");
    }
  };

  const tabs = [
    {
      label: "Geral",
      Component: (
        <DetailTab company={company.data} handleUpdateCompany={handleUpdate} />
      ),
    },
    {
      label: "Perfil da empresa",
      Component: (
        <ProfileDetails
          company={company.data}
          handleUpdateCompany={(newCompany) =>
            setCompany({
              isLoading: false,
              data: { ...company.data, ...newCompany },
            })
          }
        />
      ),
    },
  ];

  return company.isLoading ? (
    <Center w={"100%"} h={"100%"}>
      <Loading
        height="100"
        width="100"
        color="#408EC5"
        secondaryColor="#408EC5"
        radius="12.5"
        ariaLabel="mutating-dots-loading"
        visible={true}
      />
    </Center>
  ) : (
    <Center mt={"64px"} w={"100%"}>
      <VStack maxW={"638px"} w={"100%"} alignItems={"flex-start"} mb={"32px"}>
        <HStack mb={"28px"}>
          <Text
            fontWeight={400}
            fontSize={"LMD"}
            cursor={"pointer"}
            textDecor={"underline"}
            onClick={() => navigate(-1)}
          >
            {company.data.name}
          </Text>

          <Text fontWeight={400} fontSize={"LMD"}>
            {" "}
            / Editar empresa
          </Text>
        </HStack>

        <Text fontWeight={600} fontSize={"RH_xl"}>
          Editar empresa
        </Text>

        <TabNavigator
          tabs={tabs}
          currentIn={currentTab}
          handleClick={setCurrentTab}
        />

        <VStack w={"100%"} alignItems={"flex-start"} pl={"16px !important"}>
          {tabs[currentTab].Component}
        </VStack>
      </VStack>
    </Center>
  );
};

export default CompanyForm;
