import { Center, HStack, Text, VStack } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { AddButton } from "src/components/AddButton";
import AreaEmptyState from "./EmptyState";
import { Area, AreaWithProjects } from "src/types";
import { toast } from "react-toastify";
import AreaService from "src/services/area";
import { Loading } from "semente-js";
import { Modal } from "src/components/base/Modal";
import { CreateAreaForm } from "./CreateAreaForm";
import AreaList from "./List";
import AreaDetails from "./AreaDetails";

const AreasTab: React.FC<any> = () => {
  const [currentArea, setCurrentArea] = useState<AreaWithProjects>();
  const [areaIsLoading, setAreaIsLoading] = useState(false);
  const [areaFormData, setAreaFormData] = useState<Area | {} | undefined>();
  const [areas, setAreas] = useState({
    isLoading: true,
    data: undefined as undefined | AreaWithProjects[],
  });

  const refreshAreas = useCallback(async () => {
    try {
      setAreas({ isLoading: true, data: undefined });
      const data = await AreaService.getAreas();
      setAreas({ isLoading: false, data });
    } catch (err) {
      toast.error("Ocorreu um erro ao buscar as áreas");
    }
  }, []);

  useEffect(() => {
    refreshAreas();
  }, [refreshAreas]);

  const handleCreateOrUpdateArea: SubmitHandler<Area> = async (area) => {
    setAreaIsLoading(true);
    const isUpdate = "id" in area;

    try {
      if (isUpdate && areas.data) {
        await AreaService.update(area);
        const areaIndex = areas.data?.findIndex(({ id }) => id === area.id);

        if (areaIndex) {
          areas.data[areaIndex] = area as AreaWithProjects;
          setAreas({ ...areas, data: areas.data });
          setCurrentArea(area as AreaWithProjects);
        }
      } else {
        const newArea = await AreaService.create(area);
        setAreas({ ...areas, data: [...(areas.data || []), newArea] });
      }

      setAreaFormData(undefined);
    } catch (err) {
      toast.error(
        `Ocorreu um erro ao ${isUpdate ? "Atualizar" : "Criar"} sua área`
      );
    }
    setAreaIsLoading(false);
  };

  const handleAddProjects = async (area: AreaWithProjects) => {
    setCurrentArea(area as AreaWithProjects);
    await refreshAreas();
  };

  const handleDeleteArea = async (area_id: Area["id"]) => {
    const oldAreas = areas.data;

    try {
      setCurrentArea(undefined);
      setAreas({
        ...areas,
        data: areas.data?.filter(({ id }) => id !== area_id),
      });
      await AreaService.delete(area_id);
      toast.success("Área deletada com sucesso!");
    } catch (err) {
      setAreas({ ...areas, data: oldAreas });
      toast.error("Ocorreu um erro ao deletar sua área");
    }
  };

  return (
    <VStack flex={1} alignItems={"flex-start"} w={"100%"}>
      {currentArea ? (
        <AreaDetails
          area={currentArea}
          handleAddProjects={handleAddProjects}
          handleDeleteArea={handleDeleteArea}
          handleUpdateArea={setAreaFormData}
          handleBack={() => setCurrentArea(undefined)}
        />
      ) : (
        <>
          <HStack justifyContent={"space-between"} width={"100%"} pb={"16px"}>
            <Text
              fontWeight={600}
              fontFamily={"Raleway"}
              fontSize={"RH_sm"}
              color={"Gray.$800"}
            >
              Áreas
            </Text>

            <AddButton
              label={"Adicionar nova área"}
              onClick={() => setAreaFormData({})}
            />
          </HStack>
          {areas.isLoading ? (
            <Center mt={"64px !important"} w={"100%"}>
              <Loading
                height="100"
                width="100"
                color="#408EC5"
                secondaryColor="#408EC5"
                radius="12.5"
                ariaLabel="mutating-dots-loading"
                visible={true}
              />
            </Center>
          ) : (
            <>
              {areas.data && (
                <>
                  {areas.data.length >= 1 ? (
                    <AreaList
                      areas={areas.data}
                      handleClickArea={setCurrentArea}
                    />
                  ) : (
                    <AreaEmptyState handleCreate={() => setAreaFormData({})} />
                  )}
                </>
              )}
            </>
          )}
        </>
      )}

      <Modal
        title={
          areaFormData && "id" in areaFormData
            ? "Editar unidade"
            : "Adicionar unidade"
        }
        isOpen={!!areaFormData}
        onClose={() => setAreaFormData(undefined)}
      >
        {areaIsLoading ? (
          <Center minH={"300px"} w={"100%"}>
            <Loading
              height="100"
              width="100"
              color="#408EC5"
              secondaryColor="#408EC5"
              radius="12.5"
              ariaLabel="mutating-dots-loading"
              visible={true}
            />
          </Center>
        ) : (
          <CreateAreaForm
            handleSubmitArea={handleCreateOrUpdateArea}
            area={areaFormData as Area}
          />
        )}
      </Modal>
    </VStack>
  );
};

export default AreasTab;
