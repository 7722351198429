import { Button, Center, Text, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import { Loading } from "semente-js";
import Input from "src/components/base/Input/Index";
import { Props } from "./types";

const DeleteConfirmation: React.FC<Props> = ({
  handleClose,
  handleDelete,
  project,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  return (
    <VStack
      w={"100%"}
      h={"100%"}
      borderTop={"1px solid"}
      borderColor={"Gray.$400"}
    >
      {!isSubmitted ? (
        <>
          <VStack
            w={"100%"}
            h={"100%"}
            p={"24px"}
            spacing={"16px"}
            alignItems={"flex-start"}
          >
            <Text fontWeight={500} fontSize={"L_md"}>
              Tem certeza que deseja excluir esse projeto? Esse ação não poderá
              ser desfeita.
            </Text>
            <Input
              placeholder="Digite o nome do projeto"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
          </VStack>
          <VStack
            justifyContent={"flex-end"}
            p={"24px"}
            w={"100%"}
            borderTop={"1px solid"}
            borderColor={"Gray.$400"}
            background={"white"}
          >
            <Button
              variant={"Primary"}
              w={"100%"}
              borderRadius={"8px"}
              isDisabled={project.name !== inputValue}
              onClick={() => {
                handleDelete();
                setIsSubmitted(true);
              }}
            >
              Excluir projeto
            </Button>
            <Button w={"100%"} borderRadius={"8px"} onClick={handleClose}>
              Voltar
            </Button>
          </VStack>
        </>
      ) : (
        <Center height={"300px"} w={"100%"}>
          <Loading
            height="100"
            width="100"
            color="#408EC5"
            secondaryColor="#408EC5"
            radius="12.5"
            ariaLabel="mutating-dots-loading"
            visible={true}
          />
        </Center>
      )}
    </VStack>
  );
};

export default DeleteConfirmation;
