/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Center, HStack, Stack, VStack, useTheme } from "@chakra-ui/react";
import React, { useState, useEffect, useContext } from "react";
import { Loading } from "semente-js";
import { KanbanContext } from "../../contexts/KanbanContext";
import { Task, User } from "../../types";
import { AddCard } from "./AddCard";
import { Card } from "./Card";
import { Column } from "./Column";
import { adjustTasksByIndex } from "./common";
import { Header } from "./Header";
import KanbanUpdate from "./Update";
import * as _ from "lodash";
import KanbanFilter from "./Filter";
import ProjectsService from "src/services/projects";
import { ProjectContext } from "src/contexts/ProjectContext";
import FilterViewer from "./Filter/FilterViewer";
import { Icon, Input } from "semente-js";
import { useSearchParams } from "react-router-dom";
import { socket } from "../../connections/socket";
import { UserContext } from "src/contexts/UserContext";

export const KanbanPage: React.FC = () => {
  const { kanbanData, selectDialog } = useContext(KanbanContext);
  const { user } = useContext(UserContext);
  const { currentProject } = useContext(ProjectContext);
  const [showKanbanUpdate, setShowKanbanUpdate] = useState(false);
  const [columnsHidded, setColumnsHidded] = useState([""]);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [putLocale, setPutLocale] = useState(0);
  const [filterIsOpen, setFilterIsOpen] = useState(false);
  const [availableConsultants, setAvailableConsultants] = useState<User[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState({
    consultants: undefined as any,
    company: undefined as undefined | string,
  });

  const theme = useTheme();

  useEffect(() => {
    if (currentProject?.data) {
      ProjectsService.getConsultantsByProject(currentProject.data?.id).then(
        (users) => {
          const kanbanConsultants = kanbanData.data?.tasks.map(
            (task) => task.consultant_id
          ) as string[];

          setAvailableConsultants(
            users.filter((user) => kanbanConsultants?.includes(user.id))
          );
        }
      );
    }
  }, []);

  useEffect(() => {
    if (kanbanData?.data) {
      setTasks(kanbanData.data.tasks);
    }
  }, [kanbanData]);

  const onDragOver = (e: any) => {
    e.preventDefault();
  };

  const onDragStart = (e: any, id: string) => {
    e.dataTransfer.setData("id", id);
  };

  const getTaskObject = () => {
    return kanbanData.columns.map((column) => ({
      [column.id]: adjustTasksByIndex(
        tasks.filter((task) => task.column_index === column.id)
      ).map((task, index) => (
        <Card
          isHidded={columnsHidded.includes(column.id.toString())}
          setPutLocale={() => setPutLocale(index - 1)}
          onClick={() =>
            selectDialog({
              key: "taskView",
              props: {
                column,
                handleCloseModal: () => selectDialog(undefined),
                task: kanbanData?.data?.tasks.find(
                  (kTask) => kTask.id === task.id
                ),
              },
            })
          }
          onDragStart={onDragStart}
          key={task.id}
          task={task}
        />
      )),
    }));
  };

  const [tasksToRender, setTasksToRender] = useState(getTaskObject());

  const onDrop = async (e: any, columnId: string) => {
    let id = e.dataTransfer.getData("id");
    if (id) {
      const taskIndex = tasks.findIndex((task) => task.company.id === id);
      const task = tasks[taskIndex];

      if (task) {
        const localHistoric = JSON.parse(
          localStorage.getItem("rama-task-history") || "{}"
        );

        localStorage.setItem(
          "rama-task-history",
          JSON.stringify(_.omit(localHistoric, task.id))
        );

        socket.emit("task_update", {
          ...task,
          column_index: parseInt(columnId),
          row_index: putLocale,
          updatedBy: user,
        } as Task);
      }
    }
  };

  const onHideColumn = (columnId: string) => {
    if (columnsHidded.includes(columnId.toString())) {
      setColumnsHidded(columnsHidded.filter((c) => c !== columnId));
    } else {
      setColumnsHidded([...columnsHidded, columnId]);
    }
  };

  useEffect(() => {
    handleFilter({
      consultants: searchParams.getAll("consultants"),
      company: searchParams.get("company") || undefined,
    });
  }, [kanbanData]);

  const handleFilter = (value: typeof filters) => {
    setSearchParams({
      tab: searchParams.get("tab"),
      ...(value.consultants && { consultants: value.consultants }),
      ...(value.company && { company: value.company }),
    });

    if (kanbanData.data) {
      setFilters(value);
      setTasks(
        kanbanData.data?.tasks.filter(
          (task) =>
            task.company.name.toLowerCase().includes(value.company || "") &&
            (value.consultants.length < 1 ||
              value.consultants.includes(task?.consultant_id as any))
        )
      );
    }
  };

  useEffect(() => {
    setTasksToRender(getTaskObject());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks, kanbanData.columns, columnsHidded]);

  return (
    <VStack w={"100%"} h={"100%"} alignItems={"flex-start"} spacing={0}>
      {kanbanData?.isLoading ? (
        <Center w={"100%"} h={"100%"} mt={"64px !important"}>
          <Loading
            height="100"
            width="100"
            color="#408EC5"
            secondaryColor="#408EC5"
            radius="12.5"
            ariaLabel="mutating-dots-loading"
            visible={true}
          />
        </Center>
      ) : (
        kanbanData.data && (
          <>
            {showKanbanUpdate ? (
              <KanbanUpdate
                handleBack={() => setShowKanbanUpdate(false)}
                kanbanData={kanbanData.data}
              />
            ) : (
              <>
                <Header
                  kanbanData={kanbanData?.data}
                  hasFilter={
                    !!Object.keys(filters).find(
                      (key) =>
                        key !== "company" &&
                        (filters as any)[key] &&
                        (filters as any)[key].length >= 1
                    )
                  }
                  handleOpenFilters={() => setFilterIsOpen(true)}
                  handleUpdateKanban={() => setShowKanbanUpdate(true)}
                />

                <Box marginBottom={"18px"} w={"100%"}>
                  <HStack w={"full"} position={"relative"}>
                    <Input
                      icon="search"
                      onChange={(e) =>
                        handleFilter({
                          consultants: filters.consultants || [],
                          company: e.target.value,
                        })
                      }
                      value={filters.company}
                      layout="rounded"
                      id="filter-tasks-input"
                      placeholder="Busque por uma empresa"
                    />

                    <Box
                      position={"absolute"}
                      right={4}
                      cursor={"pointer"}
                      onClick={() =>
                        handleFilter({
                          consultants: filters.consultants || [],
                          company: "",
                        })
                      }
                    >
                      <Icon
                        color={(theme.colors as any).Gray.$700}
                        name={"times-circle"}
                        size={"20px"}
                      />
                    </Box>
                  </HStack>
                </Box>

                <FilterViewer
                  consultants={availableConsultants}
                  handleFilter={handleFilter}
                  tasksLen={tasks.length}
                  filter={filters}
                />

                <HStack w={"100%"} h={"100%"} overflow={"auto"} pb={"24px"}>
                  {kanbanData.columns.map((column, index) =>
                    tasksToRender[column?.id] ? (
                      <Column
                        key={column.id}
                        column={column}
                        onDragOver={onDragOver}
                        tasks={[
                          ...[
                            index === 0 && (
                              <AddCard
                                onClick={() =>
                                  selectDialog({ key: "addCompany" })
                                }
                                isHidded={columnsHidded.includes(
                                  column.id.toString()
                                )}
                              />
                            ),
                          ],
                          ...tasksToRender[column?.id][column?.id],
                        ]}
                        onDrop={(e) => onDrop(e, column.id.toString())}
                        onPutOutside={() =>
                          setPutLocale(
                            tasksToRender[column?.id][column?.id].length
                          )
                        }
                        isHidded={columnsHidded.includes(column.id.toString())}
                        onHide={() => onHideColumn(column.id.toString())}
                      />
                    ) : (
                      <Stack key={column.id} />
                    )
                  )}
                </HStack>
              </>
            )}
          </>
        )
      )}

      <KanbanFilter
        value={filters}
        isOpen={filterIsOpen}
        handleFilter={handleFilter}
        consultants={availableConsultants}
        handleClose={() => setFilterIsOpen(false)}
      />
    </VStack>
  );
};
