import {
  Button,
  Center,
  HStack,
  Text,
  VStack,
  useTheme,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Input from "../../../../../components/base/Input/Index";
import { Modal } from "../../../../../components/base/Modal";
import { Props } from "./types";
import { toast } from "react-toastify";
import { User } from "../../../../../types";
import UserService from "../../../../../services/user";
import { Loading } from "semente-js";
import { Select } from "src/components/base/Select";

const UpdateConsultant: React.FC<Props> = ({
  handleClose,
  consultant,
  handleUpdateConsultant,
}) => {
  const theme = useTheme();

  const defaultValues: User = consultant as any;
  const [emailsAvailable, setEmailsAvailable] = useState({
    isLoading: true,
    isInvalid: false,
    data: [] as string[],
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm({
    defaultValues,
  });

  const handleCloseModal = () => {
    reset(defaultValues);
    handleClose();
  };

  useEffect(() => {
    if (consultant) {
      reset(consultant);
    }
  }, [consultant, reset]);

  const handleSubmitForm: SubmitHandler<typeof defaultValues> = async (
    values
  ) => {
    try {
      await UserService.update(values.id, values);
      handleUpdateConsultant();
      handleCloseModal();
      toast.success("Membro atualizado!");
    } catch (err) {
      toast.success("Ocorreu uma falha ao enviar os convites.");
    }
  };

  useEffect(() => {
    setEmailsAvailable({
      isLoading: true,
      isInvalid: false,
      data: [] as string[],
    });
    if (!!consultant) {
      UserService.getAvailableEmailsToInvite()
        .then((data) => {
          setEmailsAvailable({ isLoading: false, isInvalid: false, data });
        })
        .catch(() => {
          setEmailsAvailable({ isLoading: false, isInvalid: true, data: [] });
        });
    }
  }, [consultant]);

  return (
    <Modal
      onClose={handleCloseModal}
      isOpen={!!consultant}
      title={"Editar membro"}
    >
      <VStack
        borderTop={"1px solid"}
        borderColor={"Gray.$400"}
        p={"24px"}
        maxW={"568px"}
        maxH={"500px"}
        overflow={"auto"}
      >
        {emailsAvailable.isInvalid ? (
          <VStack my={"32px"} spacing={0}>
            <Text fontWeight={600} fontSize={"L_lg"} color={"Gray.$800"}>
              Oops, você não possui acesso a este recurso.
            </Text>
            <Text fontWeight={600} fontSize={"L_sm"} color={"Gray.$700"}>
              Contate um administrador para ajustar suas permissões
            </Text>

            <Button
              variant={"Primary"}
              onClick={handleClose}
              mt={"24px"}
              minW={"100px"}
              minH={"48px"}
            >
              Voltar
            </Button>
          </VStack>
        ) : (
          <>
            {isSubmitting || emailsAvailable.isLoading ? (
              <Center w={"100%"}>
                <Loading
                  height="100"
                  width="100"
                  color="#408EC5"
                  secondaryColor="#408EC5"
                  radius="12.5"
                  ariaLabel="mutating-dots-loading"
                  visible={true}
                />
              </Center>
            ) : (
              <>
                {emailsAvailable.data.length >= 1 ? (
                  <>
                    <>
                      <Controller
                        name={"name"}
                        control={control}
                        render={({ field, fieldState }) => (
                          <Input
                            {...field}
                            isInvalid={!!fieldState.error}
                            placeholder={"Nome"}
                          />
                        )}
                      />

                      <Controller
                        name={"email"}
                        control={control}
                        render={({ field, fieldState }) => (
                          <Select
                            items={emailsAvailable.data.map((email) => ({
                              value: email,
                              label: email,
                            }))}
                            placeholder={"E-mail"}
                            {...(field.value && {
                              value: {
                                label: field.value,
                                value: field.value,
                              },
                            })}
                            onChange={(e: { value: string }) =>
                              field.onChange(e.value)
                            }
                            isInvalid={!!fieldState.error}
                          />
                        )}
                      />
                    </>
                  </>
                ) : (
                  <VStack my={"32px"} spacing={0}>
                    <Text
                      fontWeight={600}
                      fontSize={"L_lg"}
                      color={"Gray.$800"}
                    >
                      Oops, não há nenhum email disponível para convite.
                    </Text>
                    <Text
                      fontWeight={600}
                      fontSize={"L_sm"}
                      color={"Gray.$700"}
                    >
                      Garanta que há usuários já criados dentro do{" "}
                      <a
                        onClick={() => {
                          window.location.href =
                            process.env.REACT_APP_HUB_URL || "";
                        }}
                        href={process.env.HUB_URL}
                        style={{
                          textDecoration: "underline",
                          color: theme.colors.Brand.pure,
                          cursor: "pointer",
                        }}
                      >
                        Hub
                      </a>
                    </Text>

                    <Button
                      variant={"Primary"}
                      onClick={handleClose}
                      mt={"24px"}
                      minW={"100px"}
                      minH={"48px"}
                    >
                      Voltar
                    </Button>
                  </VStack>
                )}
              </>
            )}
          </>
        )}
      </VStack>

      {!isSubmitting &&
        !emailsAvailable.isInvalid &&
        !emailsAvailable.isLoading &&
        emailsAvailable.data.length >= 1 && (
          <HStack
            w={"100%"}
            py={"16px"}
            px={"24px"}
            borderTop={"1px solid"}
            borderColor={"Gray.$400"}
          >
            <Button
              variant={"Primary"}
              w={"100%"}
              onClick={handleSubmit(handleSubmitForm)}
            >
              Salvar alterações
            </Button>
          </HStack>
        )}
    </Modal>
  );
};

export default UpdateConsultant;
