import { Box, Button, Center, Image, Text, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Loading } from "semente-js";
import { toast } from "react-toastify";
import { logo, rocket } from "src/assets/images";
import Input from "src/components/base/Input/Index";
import { motion } from "framer-motion";
import { User } from "src/types";
import { Props } from "./types";
import UserService from "src/services/user";

const UserForm: React.FC<Props> = ({
  defaultValues,
  handleSubmitForm,
  isEmployee,
}) => {
  const {
    control,
    handleSubmit,
    watch,
    reset,
    setError,
    getValues,
    formState: { isSubmitSuccessful },
  } = useForm({ defaultValues });
  const [isLoading, setIsLoading] = useState(false);

  const handleCreateUser: SubmitHandler<User> = async (values) => {
    try {
      setIsLoading(true);
      const newUser = (
        await UserService.signup({
          ...values,
          user_company_datas: [
            {
              company_id: getValues().company.id,
              role: isEmployee ? "employee" : "founder",
            },
          ],
          profile: {
            name: isEmployee ? "employee" : "company_manager",
          },
        })
      ).user;

      reset(newUser);

      if (isEmployee) handleSubmitForm(newUser);
    } catch (err) {
      if (isEmployee) {
        toast.error("E-mail ou telefone já utilizado.");
        setError("phone", new Error("Telefone já utilizado"));
        setError("email", new Error("E-mail já utilizado"));
        setIsLoading(false);
        throw new Error("Duplicated data");
      }

      if (
        (err as any)?.response?.data?.message ===
        "Já existe um usuário na base com estes email para o app"
      ) {
        setError("email", new Error("Email já utilizado"));
        toast.error("Email já cadastrado no aplicativo para esta empresa.");
      } else {
        setError("phone", new Error("Telefone já utilizado"));
        toast.error("Número de telefone já utilizado.");
      }
      setIsLoading(false);
      throw new Error("Duplicated data");
    }

    setIsLoading(false);
  };

  return (
    <motion.div
      style={{
        flex: 1,
        alignItems: "flex-start",
        flexDirection: "column",
        width: "100%",
        display: "flex",
      }}
      initial={{ scale: 0.9 }}
      animate={{ scale: 1 }}
    >
      {isLoading ? (
        <Center w={"100%"} flex={1}>
          <Loading
            height="100"
            width="100"
            color="#408EC5"
            secondaryColor="#408EC5"
            radius="12.5"
            ariaLabel="mutating-dots-loading"
            visible={true}
          />
        </Center>
      ) : isSubmitSuccessful ? (
        <VStack w={"100%"} alignItems={"flex-start"} mt={"72px !important"}>
          <Image src={rocket} alt={"Imagem de um foguete"} />
          <Text
            fontWeight={600}
            fontSize={"RH_lg"}
            mt={"24px !important"}
            fontFamily={"Raleway"}
          >
            Tudo certo, {watch("name") || "Não definido"}!
          </Text>
          <Text
            mt={"4px !important"}
            fontWeight={400}
            fontSize={"L_md"}
            color={"Gray.$700"}
          >
            Sua conta foi criada com sucesso, e agora você já pode cadastrar sua
            empresa.
          </Text>

          <Button
            mt={"40px !important"}
            variant={"Primary"}
            w={"100%"}
            onClick={() => handleSubmitForm(getValues())}
          >
            Cadastrar empresa
          </Button>
        </VStack>
      ) : (
        <>
          <Box mb={"16px !important"}>
            <Image src={logo} alt={"Logo da rama"} />
          </Box>

          <Text
            fontFamily={"Raleway"}
            fontSize={"RH_sm"}
            color={"Gray.$800"}
            fontWeight={600}
            mb={"24px !important"}
          >
            Crie sua conta
          </Text>

          {!isEmployee && (
            <>
              <Text
                fontSize={"L_md"}
                color={"Gray.$800"}
                fontWeight={400}
                mb={"4px !important"}
              >
                E-mail
              </Text>

              <Text
                m={"0px !important"}
                fontSize={"L_md"}
                color={"Gray.$700"}
                fontWeight={400}
                mb={"24px !important"}
              >
                {watch("email")}
              </Text>
            </>
          )}

          <VStack w={"100%"} spacing={"16px"}>
            <Controller
              rules={{ required: true }}
              control={control}
              name={"name"}
              render={({ field, fieldState: { error } }) => (
                <VStack w={"100%"} alignItems={"flex-start"} spacing={0}>
                  <Input
                    {...field}
                    isInvalid={!!error}
                    placeholder={"Primeiro nome"}
                  />{" "}
                  {error && (
                    <Text
                      m={"0px !important"}
                      fontSize={"L_md"}
                      color={"red.600"}
                      fontWeight={400}
                    >
                      O nome é obrigatório
                    </Text>
                  )}
                </VStack>
              )}
            />

            <Controller
              rules={{ required: true }}
              control={control}
              name={"surname"}
              render={({ field, fieldState: { error } }) => (
                <VStack w={"100%"} alignItems={"flex-start"} spacing={0}>
                  <Input
                    {...field}
                    isInvalid={!!error}
                    placeholder={"Sobrenome"}
                  />
                  {error && (
                    <Text
                      m={"0px !important"}
                      fontSize={"L_md"}
                      color={"red.600"}
                      fontWeight={400}
                    >
                      O sobrenome é obrigatório
                    </Text>
                  )}
                </VStack>
              )}
            />
            {isEmployee && (
              <Controller
                rules={{ required: true }}
                control={control}
                name={"email"}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <Input
                      {...field}
                      isInvalid={!!error}
                      placeholder={"E-mail"}
                    />

                    {error && (
                      <Text
                        m={"0px !important"}
                        fontSize={"L_md"}
                        color={"red.600"}
                        fontWeight={400}
                      >
                        {error.type === "required"
                          ? "O email é obrigatório"
                          : "Email já utilizado"}
                      </Text>
                    )}
                  </>
                )}
              />
            )}
            <Controller
              rules={{ required: true }}
              control={control}
              name={"phone"}
              render={({ field, fieldState: { error } }) => (
                <VStack spacing={0} w={"100%"} alignItems={"flex-start"}>
                  <Input
                    {...field}
                    isInvalid={!!error}
                    placeholder={"Telefone"}
                    type={"number"}
                    variant={"text"}
                  />
                  {error && (
                    <Text
                      m={"0px !important"}
                      fontSize={"L_md"}
                      color={"red.600"}
                      fontWeight={400}
                    >
                      {error.type === "required"
                        ? "O telefone é obrigatório"
                        : "Telefone já utilizado"}
                    </Text>
                  )}
                  <Text
                    mt={"8px !important"}
                    fontSize={"L_md"}
                    color={"Gray.$700"}
                    fontWeight={400}
                  >
                    Seu número serve apenas para validação. Não enviamos spam!
                  </Text>
                </VStack>
              )}
            />

            <Controller
              rules={{ required: true, minLength: 8 }}
              control={control}
              name={"password"}
              render={({ field, fieldState: { error } }) => (
                <VStack
                  spacing={"0px !important"}
                  w={"100%"}
                  alignItems={"flex-start"}
                >
                  <Input
                    {...field}
                    type={"password"}
                    isInvalid={!!error}
                    placeholder={"Senha"}
                  />
                  {error && (
                    <Text
                      m={"0px !important"}
                      fontSize={"L_md"}
                      color={"red.600"}
                      fontWeight={400}
                    >
                      {error?.type === "minLength"
                        ? "Digite ao menos 8 caracteres"
                        : "A senha é obrigatória"}
                    </Text>
                  )}
                  <Text
                    mt={"8px !important"}
                    fontSize={"L_md"}
                    color={"Gray.$700"}
                    fontWeight={400}
                  >
                    Utilize pelo menos 8 caracteres.
                  </Text>
                </VStack>
              )}
            />

            <Text
              mt={"20px !important"}
              fontSize={"L_md"}
              color={"Gray.$700"}
              fontWeight={400}
            >
              Ao continuar, você concorda com os nossos Termos e Política de
              Privacidade.
            </Text>

            <Button
              variant={"Primary"}
              mt={"40px !important"}
              w={"100%"}
              onClick={handleSubmit(handleCreateUser)}
            >
              Criar conta
            </Button>
          </VStack>
        </>
      )}
    </motion.div>
  );
};

export default UserForm;
