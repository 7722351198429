import {
  VStack,
  useTheme,
  Box,
  Center,
  HStack,
  Text,
  Button,
} from "@chakra-ui/react";
import React, { useState, useEffect, useContext } from "react";
import { Props } from "./types";
import { createImageFromInitials } from "src/common";
import { useForm, Controller } from "react-hook-form";
import Input from "src/components/base/Input/Index";
import TextArea from "src/components/base/TextArea/Index";
import { Select } from "src/components/base/Select";
import { Area, User } from "src/types";
import UserService from "src/services/user";
import AreaService from "src/services/area";
import { Loading } from "semente-js";
import Icon from "src/components/base/Icon";
import { ProjectContext } from "src/contexts/ProjectContext";
import { motion } from "framer-motion";
import { fadeInUp } from "src/animations";
import { uploadFileToBucket } from "src/utils";
import { toast } from "react-toastify";
import ProfilePic from "src/components/ProfilePic";

const DetailsTabProject: React.FC<Props> = ({ project }) => {
  const theme = useTheme();
  const defaultValues = project;
  const [newImage, setNewImage] = useState<{
    file: File;
    base64?: string;
  }>();
  const { handleUpdateProject } = useContext(ProjectContext);
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { isSubmitting },
  } = useForm({ defaultValues });
  const [selectData, setSelectData] = useState({
    isLoading: true,
    areas: undefined as undefined | Area[],
    managers: undefined as undefined | User[],
  });

  useEffect(() => {
    (async () => {
      const [managers, areas] = await Promise.all(
        [
          UserService.getAdminUsers({ type: ["manager"] }),
          AreaService.getAreas(),
        ].map(async (service) => await service)
      );

      setSelectData({
        managers: managers as User[],
        areas,
        isLoading: false,
      });
    })();
  }, []);

  const handleUploadImage = async (file: File) => {
    if (file) {
      try {
        const params = {
          folderName: "projects",
          id: project.id || "",
        };
        const image_url = await uploadFileToBucket(file, params);

        return image_url;
      } catch (err) {
        toast.error("Erro ao atualizar o projeto.");
      }
    }
  };

  const handleConvertImage = async (file: File) => {
    const base64: string = await new Promise((resolver) => {
      const reader = new FileReader();

      reader.onload = function ({ target }) {
        resolver(target?.result as string);
      };

      reader.readAsDataURL(file);
    });

    setNewImage({ file, base64: base64 });
  };

  const handleSubmitSave = async (values: typeof defaultValues) => {
    let image_url = values.image_url;
    if (newImage) {
      image_url = (await handleUploadImage(newImage.file)) || values.image_url;
    }
    console.log("here", defaultValues);

    await handleUpdateProject({ ...values, image_url }, false);
  };

  return (
    <VStack flex={1} alignItems={"flex-start"} w={"100%"} spacing={0}>
      {selectData.isLoading ? (
        <Center height={"300px"} w={"100%"}>
          <Loading
            height="100"
            width="100"
            color="#408EC5"
            secondaryColor="#408EC5"
            radius="12.5"
            ariaLabel="mutating-dots-loading"
            visible={true}
          />
        </Center>
      ) : (
        selectData?.managers &&
        selectData.areas && (
          <motion.div {...fadeInUp} style={{ width: "100%" }}>
            <Box mb={"36px"} w={"100%"} justifyContent={"flex-start"}>
              <ProfilePic
                imageUrl={newImage?.base64 || project?.image_url}
                defaultImage={createImageFromInitials({
                  background: (theme.colors as any).Tertiary.pure,
                  color: (theme.colors as any).white,
                  name: project.name.substring(0, 2) || "",
                  size: 116,
                  fontSize: 64,
                })}
                onSelectImage={handleConvertImage}
                onRemoveImage={console.log}
                rounded
              />
            </Box>

            <VStack w={"100%"} spacing={"16px"} alignItems={"flex-start"}>
              <Controller
                control={control}
                name={"name"}
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    {...field}
                    isInvalid={!!error}
                    placeholder={"Nome do projeto"}
                  />
                )}
              />

              <Controller
                control={control}
                name={"description"}
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <TextArea
                    {...field}
                    isInvalid={!!error}
                    placeholder={"Sobre o projeto"}
                  />
                )}
              />

              <Controller
                name={"manager"}
                control={control}
                rules={{ required: true }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <Box w={"100%"}>
                    <Select
                      items={(selectData.managers || []).map((user) => ({
                        label: user?.name || "",
                        value: user?.id || "",
                      }))}
                      placeholder={"Gestor de carteira"}
                      onChange={(e) => {
                        setValue("manager_id", e.value);
                        onChange(e);
                      }}
                      {...((value?.id || (value as any)?.value) && {
                        value: {
                          label: value?.name || (value as any)?.label,
                          value: value?.id || (value as any)?.value,
                        },
                      })}
                      isInvalid={!!error}
                    />
                  </Box>
                )}
              />

              <Controller
                name={"area"}
                control={control}
                rules={{ required: true }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <Box w={"100%"}>
                    <Select
                      items={(selectData.areas || []).map((area) => ({
                        label: area?.name || "",
                        value: area?.id || "",
                      }))}
                      placeholder={"Unidade"}
                      onChange={(e) => {
                        setValue("area_id", e.value);
                        onChange(e);
                      }}
                      {...((value?.id || (value as any)?.value) && {
                        value: {
                          label: value?.name || (value as any)?.label,
                          value: value?.id || (value as any)?.value,
                        },
                      })}
                      isInvalid={!!error}
                    />
                  </Box>
                )}
              />

              <HStack spacing={"10px"} mt={"32px !important"}>
                <Icon name={"schedule"} />

                <Text fontWeight={600} fontSize={"L_md"}>
                  Período previsto do projeto
                </Text>
              </HStack>

              <HStack w={"100%"} spacing={"16px"}>
                <Controller
                  name={"start_date"}
                  control={control}
                  rules={{ required: true }}
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      {...field}
                      variant={"date"}
                      value={field?.value?.toString()}
                      placeholder={"Inicio previsto"}
                      width={"100%"}
                      isInvalid={!!error}
                    />
                  )}
                />

                <Controller
                  name={"end_date"}
                  control={control}
                  rules={{ required: true }}
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      {...field}
                      variant={"date"}
                      value={field?.value?.toString()}
                      placeholder={"Final previsto"}
                      width={"100%"}
                      minDate={watch("start_date")?.toString()}
                      isInvalid={!!error}
                    />
                  )}
                />
              </HStack>

              <Button
                onClick={handleSubmit(handleSubmitSave)}
                mt={"32px !important"}
                borderRadius={"8px"}
                variant={"Primary"}
                isLoading={isSubmitting}
              >
                Salvar alterações
              </Button>
            </VStack>
          </motion.div>
        )
      )}
    </VStack>
  );
};

export default DetailsTabProject;
