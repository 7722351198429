import {
  Box,
  Button,
  Center,
  HStack,
  Image,
  Stack,
  Text,
  VStack,
  useTheme,
} from "@chakra-ui/react";
import React, { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import { Props } from "./types";
import { Loading } from "semente-js";
import { saveAs } from "file-saver";

import InviteService from "src/services/invite";
import { AdminProfiles } from "src/types";
import { MembersModel } from "src/models";
import { Modal } from "src/components/base/Modal";
import { doneSuperIcon } from "src/assets/images";
import Icon from "src/components/base/Icon";
import { Select } from "src/components/base/Select";
import { ProfileToLabel } from "../common";
import UserService from "src/services/user";

const InviteMember: React.FC<Props> = ({ isOpen, handleClose }) => {
  const theme = useTheme();
  const [inviteByPlan, setInviteByPlan] = useState(false);
  const [emailsAvailable, setEmailsAvailable] = useState({
    isLoading: true,
    isInvalid: false,
    data: [] as string[],
  });

  const defaultValues = {
    members: [
      {
        email: "",
        profile_name: "" as AdminProfiles["name"] | undefined,
      },
    ],
  };

  enum LabelToProfile {
    "Pessoa Consultora" = "consulting",
    "Gestor(a) de Carteira" = "manager",
  }

  const {
    control,
    handleSubmit,
    reset,
    setError,
    clearErrors,
    watch,
    formState: { isSubmitting, isSubmitSuccessful },
  } = useForm({
    defaultValues,
  });

  const handleCloseModal = () => {
    reset(defaultValues);
    handleClose();
  };

  const handleSubmitForm: SubmitHandler<typeof defaultValues> = async (
    values
  ) => {
    try {
      await InviteService.sendInvites({
        consultants: values.members as {
          profile_name: AdminProfiles["name"];
          email: string;
        }[],
      });
    } catch (err) {
      toast.success("Ocorreu uma falha ao enviar os convites.");
    }
  };

  useEffect(() => {
    if (!isOpen) {
      reset(defaultValues);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      try {
        const file = acceptedFiles[0];
        const reader = new FileReader();
        reader.onload = (evt: any) => {
          const bstr = evt.target.result;
          const wb = XLSX.read(bstr, { type: "binary" });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const jsonObject: {
            ["Email do membro"]: string;
            ["Função"]: string;
          }[] = XLSX.utils.sheet_to_json(ws, {});

          reset({
            members: [
              ...watch("members").filter((value) => value.email !== ""),
              ...jsonObject
                .map((data) => ({
                  email: data["Email do membro"],
                  profile_name:
                    data["Função"] in LabelToProfile
                      ? ((LabelToProfile as any)[
                          data["Função"]
                        ] as AdminProfiles["name"])
                      : undefined,
                }))
                .filter((user) => emailsAvailable.data.includes(user.email)),
            ],
          });
          setInviteByPlan(false);
        };
        reader.readAsBinaryString(file);
      } catch (err) {
        toast.error("Ocorreu um erro ao importar sua planilha.");
        setInviteByPlan(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reset, watch, LabelToProfile]
  );

  const handleDownloadExample = () => {
    saveAs(MembersModel, "Template para criação de membros");
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  useEffect(() => {
    setEmailsAvailable({
      isLoading: true,
      isInvalid: false,
      data: [] as string[],
    });
    if (isOpen) {
      UserService.getAvailableEmailsToInvite()
        .then((data) => {
          setEmailsAvailable({ isLoading: false, isInvalid: false, data });
        })
        .catch(() => {
          setEmailsAvailable({ isLoading: false, isInvalid: true, data: [] });
        });
    }
  }, [isOpen]);

  return (
    <Modal
      onClose={handleCloseModal}
      isOpen={isOpen}
      title={isSubmitSuccessful || isSubmitting ? "" : "Convidar novos membros"}
    >
      <VStack
        w={"100%"}
        maxW={"568px"}
        maxH={"460px"}
        borderTop={"1px solid"}
        borderColor={"Gray.$400"}
      >
        {emailsAvailable.isInvalid ? (
          <VStack my={"32px"} spacing={0}>
            <Text fontWeight={600} fontSize={"L_lg"} color={"Gray.$800"}>
              Oops, você não possui acesso a este recurso.
            </Text>
            <Text fontWeight={600} fontSize={"L_sm"} color={"Gray.$700"}>
              Contate um administrador para ajustar suas permissões
            </Text>

            <Button
              variant={"Primary"}
              onClick={handleClose}
              mt={"24px"}
              minW={"100px"}
              minH={"48px"}
            >
              Voltar
            </Button>
          </VStack>
        ) : (
          <>
            {isSubmitting || emailsAvailable.isLoading ? (
              <Center flex={1} minH={"200px"}>
                <Loading
                  height="100"
                  width="100"
                  color="#408EC5"
                  secondaryColor="#408EC5"
                  radius="12.5"
                  ariaLabel="mutating-dots-loading"
                  visible={true}
                />
              </Center>
            ) : (
              <>
                {emailsAvailable.data.length >= 1 ? (
                  <>
                    {isSubmitSuccessful ? (
                      <VStack p={"24px"} w={"100%"} alignItems={"flex-start"}>
                        <Image src={doneSuperIcon} alt={"Ícone de sucesso"} />

                        <Text
                          mt={"24px !important"}
                          fontWeight={600}
                          fontSize={"RH_lg"}
                          fontFamily={"Raleway"}
                        >
                          Convites enviados!
                        </Text>

                        <VStack
                          spacing={"16px"}
                          w={"100%"}
                          mt={"24px !important"}
                        >
                          {watch("members").map((member, index) => (
                            <HStack
                              key={index}
                              p={"16px"}
                              w={"100%"}
                              background={"Gray.$200"}
                              borderRadius={"12px"}
                            >
                              <Icon name="user" size="22px" />

                              <VStack
                                alignItems={"flex-start"}
                                ml={"16px !important"}
                              >
                                <Text
                                  fontWeight={600}
                                  fontSize={"L_sm"}
                                  color={"Gray.$800"}
                                >
                                  {member.email}
                                </Text>

                                {member.profile_name && (
                                  <Text
                                    fontWeight={400}
                                    fontSize={"L_sm"}
                                    color={"Gray.$700"}
                                  >
                                    {ProfileToLabel[member.profile_name]}
                                  </Text>
                                )}
                              </VStack>
                            </HStack>
                          ))}
                        </VStack>
                      </VStack>
                    ) : (
                      <VStack py={"24px"} px={"12px"} w={"100%"}>
                        {inviteByPlan ? (
                          <VStack
                            w={"100%"}
                            alignItems={"flex-start"}
                            px={"12px"}
                          >
                            <Text fontSize={"L_lg"} fontWeight={600}>
                              Convidar através de planilha
                            </Text>
                            <Text
                              fontSize={"L_sm"}
                              fontWeight={400}
                              color={"Gray.$700"}
                            >
                              Faça o download do modelo da planilha e preencha
                              com os nomes das empresas e os e-mails dos
                              representantes.
                            </Text>
                            <HStack
                              my={"16px !important"}
                              cursor={"pointer"}
                              onClick={handleDownloadExample}
                            >
                              <Icon
                                name={"arrow-circle-down"}
                                color={"#2F77A9"}
                              />
                              <Text color={"Tertiary.dark"} fontWeight={600}>
                                Baixar modelo de planilha
                              </Text>
                            </HStack>
                            <Center
                              w={"100%"}
                              background={"Gray.$200"}
                              border={"1px solid"}
                              borderColor={"Gray.$400"}
                              borderStyle={"dashed"}
                              borderRadius={"12px"}
                              height={"158px"}
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              {isDragActive ? (
                                <Text fontFamily={"lato"} fontSize={"l_md"}>
                                  Solte seu arquivo aqui!
                                </Text>
                              ) : (
                                <VStack>
                                  <Text fontFamily={"lato"} fontSize={"l_md"}>
                                    Solte aqui o arquivo da planilha
                                  </Text>
                                  <Button mt={"10px !important"}>
                                    Procurar arquivo
                                  </Button>
                                </VStack>
                              )}
                            </Center>
                          </VStack>
                        ) : (
                          <Controller
                            name={"members"}
                            control={control}
                            render={({
                              field: { value, onChange },
                              fieldState,
                            }) => (
                              <VStack
                                w={"100%"}
                                alignItems={"flex-start"}
                                spacing={"16px !important"}
                              >
                                {value.map((data, index) => (
                                  <HStack key={index} w={"100%"}>
                                    <HStack w={"100%"} spacing={"16px"}>
                                      <Select
                                        items={emailsAvailable.data.map(
                                          (email) => ({
                                            value: email,
                                            label: email,
                                          })
                                        )}
                                        placeholder={"E-mail"}
                                        {...(data.email && {
                                          value: {
                                            label: data.email,
                                            value: data.email,
                                          },
                                        })}
                                        onChange={(e: { value: string }) => {
                                          clearErrors("members");
                                          value[index] = {
                                            ...value[index],
                                            email: e.value,
                                          };
                                          onChange([...value]);
                                        }}
                                        isInvalid={
                                          index === value.length - 1 &&
                                          !!fieldState.error
                                        }
                                      />

                                      <Stack />

                                      <Select
                                        items={(
                                          Object.keys(ProfileToLabel) as Array<
                                            keyof typeof ProfileToLabel
                                          >
                                        )
                                          .filter((key) => key !== "admin")
                                          .map((key) => ({
                                            value: key,
                                            label: ProfileToLabel[key],
                                          }))}
                                        placeholder={"Função"}
                                        {...(data.profile_name && {
                                          value: {
                                            label:
                                              ProfileToLabel[data.profile_name],
                                            value: data.profile_name,
                                          },
                                        })}
                                        onChange={(e: { value: string }) => {
                                          clearErrors("members");
                                          value[index] = {
                                            ...value[index],
                                            profile_name:
                                              e.value as AdminProfiles["name"],
                                          };
                                          onChange([...value]);
                                        }}
                                        isInvalid={
                                          index === value.length - 1 &&
                                          !!fieldState.error
                                        }
                                      />
                                    </HStack>

                                    {value.length >= 2 && (
                                      <Box
                                        cursor={"pointer"}
                                        ml={"18px !important"}
                                        onClick={() => {
                                          value.splice(index, 1);
                                          onChange([...value]);
                                        }}
                                      >
                                        <Icon name={"multiply"} size={"22px"} />
                                      </Box>
                                    )}
                                  </HStack>
                                ))}

                                <Button
                                  onClick={() => {
                                    const lastValue = value[value.length - 1];
                                    if (
                                      lastValue.email === "" ||
                                      !lastValue.profile_name
                                    ) {
                                      setError(
                                        "members",
                                        new Error("Invalid last field")
                                      );
                                      return;
                                    }
                                    onChange([
                                      ...value,
                                      defaultValues.members[0],
                                    ]);
                                  }}
                                >
                                  <HStack spacing={"8px !important"}>
                                    <Icon name={"plus"} />
                                    <Text>Adicionar mais</Text>
                                  </HStack>
                                </Button>
                              </VStack>
                            )}
                          />
                        )}
                      </VStack>
                    )}
                  </>
                ) : (
                  <VStack my={"32px"} spacing={0}>
                    <Text
                      fontWeight={600}
                      fontSize={"L_lg"}
                      color={"Gray.$800"}
                    >
                      Oops, não há nenhum email disponível para convite.
                    </Text>
                    <Text
                      fontWeight={600}
                      fontSize={"L_sm"}
                      color={"Gray.$700"}
                    >
                      Garanta que o novo usuário já está criado dentro do{" "}
                      <a
                        onClick={() => {
                          window.location.href =
                            process.env.REACT_APP_HUB_URL || "";
                        }}
                        href={process.env.HUB_URL}
                        style={{
                          textDecoration: "underline",
                          color: theme.colors.Brand.pure,
                          cursor: "pointer",
                        }}
                      >
                        Hub
                      </a>
                    </Text>

                    <Button
                      variant={"Primary"}
                      onClick={handleClose}
                      mt={"24px"}
                      minW={"100px"}
                      minH={"48px"}
                    >
                      Voltar
                    </Button>
                  </VStack>
                )}
              </>
            )}
          </>
        )}
      </VStack>

      {!isSubmitting &&
        !emailsAvailable.isLoading &&
        !emailsAvailable.isInvalid &&
        emailsAvailable.data.length >= 1 && (
          <HStack
            w={"100%"}
            py={"16px"}
            px={"24px"}
            borderTop={"1px solid"}
            borderColor={"Gray.$400"}
            justifyContent={"space-between"}
          >
            {!isSubmitSuccessful ? (
              <>
                <Text
                  fontWeight={600}
                  color={"Tertiary.dark"}
                  cursor={"pointer"}
                  onClick={() => setInviteByPlan(!inviteByPlan)}
                >
                  {inviteByPlan
                    ? "Preencher e-mails manualmente"
                    : "Enviar planilha com e-mails"}
                </Text>
                <Button
                  variant={"Primary"}
                  isDisabled={
                    !!watch("members").find(
                      ({ email, profile_name }) => email === "" || !profile_name
                    )
                  }
                  onClick={handleSubmit(handleSubmitForm)}
                >
                  Enviar convites
                </Button>
              </>
            ) : (
              <>
                <Text />
                <Button variant={"Primary"} onClick={handleClose}>
                  Concluído
                </Button>
              </>
            )}
          </HStack>
        )}
    </Modal>
  );
};

export default InviteMember;
