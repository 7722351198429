import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "src/components/base/Modal";
import AuthService, { UserSignUpParams } from "src/services/auth";
import StorageService from "../../services/storage/storage.service";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { User } from "../../types";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { Button, Center, HStack, Text, VStack } from "@chakra-ui/react";
import Input from "src/components/base/Input/Index";
import { Loading } from "semente-js";
import { useSementeAuth } from "semente-js";

export type UserContextProps = {
  handleSignUp: (props: UserSignUpParams) => Promise<void>;
  openChangePassword: () => void;
  userAlredyLoaded?: boolean;
  logout: () => void;
  user?: User;
};

export const UserContext = React.createContext<UserContextProps>(
  {} as UserContextProps
);

interface Props {
  children: React.ReactNode;
}

const defaultValues = { password: "", repassword: "" };

const schema = yup
  .object({
    password: yup
      .string()
      .required("Preencha o campo")
      .min(8, "Precisa conter pelo menos 8 caracteres"),
    repassword: yup
      .string()
      .required("Preencha o campo")
      .oneOf([yup.ref("password")], "As senhas devem ser iguais"),
  })
  .required();

const UserContextProvider = ({ children }: Props) => {
  const [showChangePassword, setShowChangePassword] = useState<boolean>(false);
  const [userAlredyLoaded, setuserAlredyLoaded] = useState<boolean>(false);
  const [user, setUser] = useState<User>();
  const { getUser, signOut } = useSementeAuth();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm({ defaultValues, resolver: yupResolver(schema) });

  const handleSubmitPassword: SubmitHandler<typeof defaultValues> = async ({
    password,
  }) => {
    try {
      await AuthService.changePassword({ password });
      reset();
      setShowChangePassword(false);
      toast.success("Senha atualizada com sucesso!");
    } catch (err) {
      toast.error("Ocorreu um erro ao atualizar a senha.");
    }
  };

  useEffect(() => {
    const user = getUser();
    if (user.email) {
      AuthService.login()
        .then((response) => {
          setUser(response.data);
          StorageService.setAccessToken(response.headers.token || "");
        })
        .catch(() => {
          window.location.href = `${process.env.REACT_APP_HUB_URL}?errorMessage=Você%20não%20possui%20conta%20criada%20na%20rama`;
        })
        .finally(() => {
          setuserAlredyLoaded(true);
        });
    }
  }, [getUser]);

  useEffect(() => {
    if (user) {
      StorageService.setUser(user);
    }
  }, [user]);

  const openChangePassword = () => setShowChangePassword(true);

  const handleSignUp = async (props: UserSignUpParams) => {
    const response = await AuthService.signUp(props);

    StorageService.setAccessToken(response.headers.token || "");
    setUser(response.data);
    navigate("/");
  };

  const logout = () => {
    setUser(undefined);
    StorageService.cleanStorage();
    signOut();
  };

  return (
    <UserContext.Provider
      value={{
        handleSignUp,
        logout,
        user,
        userAlredyLoaded,
        openChangePassword,
      }}
    >
      {children}

      <Modal
        isOpen={showChangePassword}
        onClose={() => setShowChangePassword(false)}
        title={"Alteração de senha"}
      >
        {isSubmitting ? (
          <Center minH={"200px"} w={"100%"}>
            <Loading
              height="100"
              width="100"
              color="#408EC5"
              secondaryColor="#408EC5"
              radius="12.5"
              ariaLabel="mutating-dots-loading"
              visible={true}
            />
          </Center>
        ) : (
          <>
            <VStack
              p={"24px 16px"}
              borderTop={"1px solid"}
              borderColor={"Gray.$400"}
              alignItems={"flex-start"}
            >
              <Controller
                name="password"
                rules={{ required: true }}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    {...field}
                    type={"password"}
                    placeholder={"Nova senha"}
                    isInvalid={!!error}
                  />
                )}
              />
              <Controller
                name="repassword"
                rules={{ required: true }}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <VStack alignItems={"flex-start"} w={"100%"}>
                    <Input
                      {...field}
                      type={"password"}
                      placeholder={"Confirmar senha"}
                      isInvalid={!!error}
                    />
                    <Text
                      mt={"4px !important"}
                      fontSize={"L_sm"}
                      fontWeight={400}
                      color={"Gray.$700"}
                      mb={"0px !important"}
                    >
                      Utilize pelo menos 8 caracteres.
                    </Text>
                  </VStack>
                )}
              />
            </VStack>

            <HStack
              w={"100%"}
              py={"16px"}
              px={"24px"}
              borderTop={"1px solid"}
              borderColor={"Gray.$400"}
              justifyContent={"space-between"}
            >
              <Button
                width={"100%"}
                variant={"Primary"}
                onClick={handleSubmit(handleSubmitPassword)}
              >
                Alterar senha
              </Button>
            </HStack>
          </>
        )}
      </Modal>
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const context = useContext(UserContext);

  if (context) return context;

  throw new Error("useUserContext must be used within a UserContextProvider.");
};

export default UserContextProvider;
