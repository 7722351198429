import {
  Box,
  Button,
  Center,
  HStack,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useCallback, useState, useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Loading } from "semente-js";
import { toast } from "react-toastify";
import { conffeti } from "src/assets/images";
import Input from "src/components/base/Input/Index";
import { motion } from "framer-motion";
import { Company } from "src/types";
import { Props } from "./types";
import cep from "cep-promise";
import { Select } from "src/components/base/Select";
import { States } from "./consts";
import Icon from "src/components/base/Icon";
import { masks } from "src/components/base/Input/masks";
import CompanyService from "src/services/company";
import Checkbox from "src/components/Checkbox";
import { validateCNPJ } from "src/common";

const CompanyForm: React.FC<Props> = ({
  defaultValues: { id, company },
  handleSubmitForm,
}) => {
  const {
    control,
    handleSubmit,
    trigger,
    watch,
    reset,
    setValue,
    setError,
    clearErrors,
    getValues,
    formState: { isSubmitting, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      ...company,
      hasCNPJ: false,
      address: { ...company.address, country: "BR" },
    },
  });
  const [cepInvalid, setCepInvalid] = useState(false);
  const [timer, setTimer] = useState<any>(null);
  const [showingDetails, setShowingDetails] = useState(false);
  const cnpjWatcher = watch("cnpj");

  useEffect(() => {
    if (
      cnpjWatcher &&
      cnpjWatcher.length === 14 &&
      !validateCNPJ(cnpjWatcher as string)
    ) {
      toast.error("CNPJ inválido.");
      setError("cnpj", new Error("Cnpj inválido."));

      return;
    }

    clearErrors("cnpj");
  }, [cnpjWatcher, setError, clearErrors]);

  const handleCreateCompany: SubmitHandler<Company> = async (values) => {
    try {
      const Company = await CompanyService.create(
        { ...values, status: "pending", representative_id: id },
        id
      );
      reset(Company[0].company);
    } catch (err) {
      toast.error("Ocorreu um erro ao criar a empresa.");
    }
  };

  const onCepFieldChange = useCallback(
    async (zip: string) => {
      const values = getValues();

      try {
        const addressInfo = await cep(zip.replace("-", ""));
        reset({
          ...values,
          address: {
            zip: values.address.zip,
            city: addressInfo.city,
            state_province: addressInfo.state,
            address: addressInfo.street,
            country: values.address.country,
          },
        });
      } catch (err) {
        setCepInvalid(true);
        setError("address.zip", new Error("Cep inválido."));
        setValue("address.state_province", "");
      }
    },
    [getValues, reset, setValue, setError]
  );

  const handleZipChange = (value: string) => {
    clearErrors("address.zip");
    setCepInvalid(false);
    setValue("address.zip", value);
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }

    setTimer(setTimeout(() => onCepFieldChange(value), 500));
  };

  const stateWatcher = watch("address.state_province");

  return (
    <motion.div
      style={{
        flex: 1,
        alignItems: "flex-start",
        flexDirection: "column",
        width: "100%",
        display: "flex",
      }}
      initial={{ scale: 0.9 }}
      animate={{ scale: 1 }}
    >
      {isSubmitting ? (
        <Center w={"100%"} flex={1}>
          <Loading
            height="100"
            width="100"
            color="#408EC5"
            secondaryColor="#408EC5"
            radius="12.5"
            ariaLabel="mutating-dots-loading"
            visible={true}
          />
        </Center>
      ) : isSubmitSuccessful ? (
        <VStack w={"100%"} alignItems={"flex-start"} mt={"72px !important"}>
          <Image src={conffeti} alt={"Imagem de confete"} />
          <Text
            fontWeight={600}
            fontSize={"RH_lg"}
            mt={"24px !important"}
            fontFamily={"Raleway"}
          >
            Cadastro criado com sucesso
          </Text>
          <Text
            mt={"4px !important"}
            fontWeight={400}
            fontSize={"L_md"}
            color={"Gray.$700"}
          >
            A empresa <b>{watch("name")}</b> está cadastrada. Continue para
            criar o perfil da empresa.
          </Text>

          <Button
            mt={"40px !important"}
            variant={"Primary"}
            w={"100%"}
            onClick={() => handleSubmitForm(getValues())}
          >
            Criar perfil da empresa
          </Button>
        </VStack>
      ) : (
        <>
          {showingDetails ? (
            <>
              <Box
                cursor={"pointer"}
                mt={"14px !important"}
                transform={"rotate(0.25turn)"}
                onClick={() => setShowingDetails(false)}
              >
                <Icon name={"angle-down"} color={"black"} size={"24px"} />
              </Box>

              <Text
                fontFamily={"Raleway"}
                fontSize={"RH_sm"}
                color={"Gray.$800"}
                fontWeight={600}
                mt={"28px !important"}
                mb={"40px !important"}
              >
                Confirme os detalhes do cadastro
              </Text>

              <VStack w={"100%"} spacing={"0px !important"}>
                <HStack
                  w={"100%"}
                  spacing={"16px"}
                  borderBottom={"1px solid"}
                  borderColor={"Gray.$400"}
                  py={"16px"}
                >
                  <VStack
                    flex={1}
                    spacing={"4px !important"}
                    alignItems={"flex-start"}
                  >
                    <Text fontWeight={400} fontSize={"L_md"}>
                      Nome da empresa
                    </Text>
                    <Text
                      fontWeight={400}
                      fontSize={"L_md"}
                      color={"Gray.$700"}
                    >
                      {watch("name")}
                    </Text>
                  </VStack>

                  <Text
                    fontWeight={600}
                    fontSize={"L_md"}
                    cursor={"pointer"}
                    color={"Tertiary.dark"}
                    onClick={() => setShowingDetails(false)}
                  >
                    Editar
                  </Text>
                </HStack>

                <HStack
                  w={"100%"}
                  spacing={"16px"}
                  borderBottom={"1px solid"}
                  borderColor={"Gray.$400"}
                  py={"16px"}
                >
                  <VStack
                    flex={1}
                    spacing={"4px !important"}
                    alignItems={"flex-start"}
                  >
                    <Text fontWeight={400} fontSize={"L_md"}>
                      CNPJ
                    </Text>
                    <Text
                      fontWeight={400}
                      fontSize={"L_md"}
                      color={"Gray.$700"}
                    >
                      {watch("cnpj")
                        ? masks["cnpj"](watch("cnpj") || "")
                        : "Não definido"}
                    </Text>
                  </VStack>

                  <Text
                    fontWeight={600}
                    fontSize={"L_md"}
                    cursor={"pointer"}
                    color={"Tertiary.dark"}
                    onClick={() => setShowingDetails(false)}
                  >
                    Editar
                  </Text>
                </HStack>

                <HStack w={"100%"} spacing={"16px"} py={"16px"}>
                  <VStack
                    flex={1}
                    spacing={"4px !important"}
                    alignItems={"flex-start"}
                  >
                    <Text fontWeight={400} fontSize={"L_md"}>
                      Endereço
                    </Text>
                    <Text
                      fontWeight={400}
                      fontSize={"L_md"}
                      color={"Gray.$700"}
                    >
                      {`${watch("address.address")}, ${watch(
                        "address.number"
                      )}, ${watch("address.city")}, ${watch(
                        "address.state_province"
                      )}, ${watch("address.zip")}`}
                    </Text>
                  </VStack>

                  <Text
                    fontWeight={600}
                    fontSize={"L_md"}
                    cursor={"pointer"}
                    color={"Tertiary.dark"}
                    onClick={() => setShowingDetails(false)}
                  >
                    Editar
                  </Text>
                </HStack>
              </VStack>

              <Button
                variant={"Primary"}
                mt={"40px !important"}
                w={"100%"}
                onClick={handleSubmit(handleCreateCompany)}
              >
                Confirmar
              </Button>
            </>
          ) : (
            <>
              <Text
                fontFamily={"Raleway"}
                fontSize={"RH_sm"}
                color={"Gray.$800"}
                fontWeight={600}
                mb={"24px !important"}
              >
                Cadastre sua empresa
              </Text>
              <VStack w={"100%"} spacing={"16px"} alignItems={"flex-start"}>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name={"name"}
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      {...field}
                      isInvalid={!!error}
                      placeholder={"Nome da empresa"}
                    />
                  )}
                />

                <Controller
                  rules={{ required: !watch("hasCNPJ") }}
                  control={control}
                  name={"cnpj"}
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      {...field}
                      isInvalid={!!error}
                      placeholder={"CNPJ"}
                      isDisabled={watch("hasCNPJ")}
                      variant={"cnpj"}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name={"hasCNPJ"}
                  render={({ field }) => (
                    <HStack>
                      <Checkbox
                        isChecked={field.value}
                        onChange={field.onChange}
                      />
                      <Text fontSize={"L_md"} fontWeight={"400"}>
                        Minha empresa não tem CNPJ
                      </Text>
                    </HStack>
                  )}
                />

                <Controller
                  rules={{ required: true }}
                  control={control}
                  name={"address.zip"}
                  render={({ field, fieldState: { error } }) => (
                    <VStack spacing={"8px !important"}>
                      <Input
                        onChange={(e) => handleZipChange(e.target.value)}
                        value={field.value}
                        isInvalid={!!error}
                        placeholder={"CEP"}
                        variant={"cep"}
                      />
                      {error && (
                        <Text
                          m={"0px !important"}
                          fontSize={"L_md"}
                          color={"red.600"}
                          fontWeight={400}
                          alignSelf={"start"}
                        >
                          {"Cep inválido"}
                        </Text>
                      )}

                      <Text
                        fontSize={"L_md"}
                        fontWeight={"400"}
                        color={"Gray.$700"}
                      >
                        Caso sua empresa não tenha um endereço, coloque o seu
                        endereço residencial
                      </Text>
                    </VStack>
                  )}
                />

                {stateWatcher && (
                  <>
                    <Controller
                      rules={{ required: true }}
                      control={control}
                      name={"address.address"}
                      render={({ field, fieldState: { error } }) => (
                        <Input
                          {...field}
                          isInvalid={!!error}
                          placeholder={"Endereço"}
                        />
                      )}
                    />

                    <Controller
                      rules={{ required: true }}
                      control={control}
                      name={"address.number"}
                      render={({ field, fieldState: { error } }) => (
                        <Input
                          {...field}
                          isInvalid={!!error}
                          type={"number"}
                          placeholder={"Número"}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name={"address.complement"}
                      render={({ field, fieldState: { error } }) => (
                        <Input
                          {...field}
                          isInvalid={!!error}
                          placeholder={"Complemento (opcional)"}
                        />
                      )}
                    />

                    <Controller
                      rules={{ required: true }}
                      control={control}
                      name={"address.city"}
                      render={({ field, fieldState: { error } }) => (
                        <Input
                          {...field}
                          isInvalid={!!error}
                          placeholder={"Cidade"}
                        />
                      )}
                    />

                    <Controller
                      rules={{ required: true }}
                      control={control}
                      name={"address.state_province"}
                      render={({ field, fieldState: { error } }) => (
                        <Box w={"100%"}>
                          <Select
                            placeholder="Estado"
                            items={States}
                            value={{
                              value: field.value,
                              label:
                                States.find((e) => e.value === field.value)
                                  ?.label || "",
                            }}
                            onChange={(e) => field.onChange(e.value)}
                          />
                        </Box>
                      )}
                    />
                  </>
                )}

                <Button
                  variant={"Primary"}
                  mt={"40px !important"}
                  w={"100%"}
                  onClick={async () => {
                    if (!cepInvalid) {
                      const output = await trigger();
                      if (output) setShowingDetails(true);
                    }
                  }}
                >
                  Continuar
                </Button>
              </VStack>
            </>
          )}
        </>
      )}
    </motion.div>
  );
};

export default CompanyForm;
