import {
  Center,
  Image,
  Stack,
  Text,
  useOutsideClick,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { Loading } from "semente-js";
import Resizer from "react-image-file-resizer";
import Icon from "../base/Icon";
import { Props } from "./types";
import { toast } from "react-toastify";

const ProfilePic: React.FC<Props> = ({
  rounded,
  imageUrl,
  defaultImage,
  onSelectImage,
  onRemoveImage,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const menuRef = useRef<any>();
  const inputRef = useRef<any>();

  useOutsideClick({
    ref: menuRef,
    handler: () => setMenuIsOpen(false),
  });

  const resizeFile = (file: File) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        600,
        600,
        file.type.split("/")[1],
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const handleSelectImage = async (file: File) => {
    setIsLoading(true);
    if (file.size >= 1000000) {
      file = (await resizeFile(file)) as File;
    }
    if (file.size >= 2000000) {
      toast.warn(
        "Sua imagem ultrapassa 2Mb mesmo compactada, não recomendamos imagens deste tamanho."
      );
    }
    onSelectImage(file);
  };

  const menuOptions = [
    {
      title: "Alterar foto",
      color: "black",
      onClick: () => inputRef.current.click(),
    },
    {
      title: "Remover foto",
      color: "#CC0000",
      onClick: onRemoveImage,
    },
  ];

  useEffect(() => {
    setIsLoading(false);
  }, [imageUrl]);

  return (
    <Stack
      flexDirection="column"
      alignItems="center"
      width={"fit-content"}
      position={"relative"}
    >
      <Stack
        width="120px"
        height="120px"
        overflow="hidden"
        borderRadius="60px"
        alignItems="center"
        background={imageUrl || defaultImage ? "transparent" : "#E3E5E8"}
        justifyContent="center"
      >
        {isLoading ? (
          <Center w={"100%"} h={"100%"}>
            <Loading
              height="100"
              width="100"
              color="#408EC5"
              secondaryColor="#408EC5"
              radius="12.5"
              ariaLabel="mutating-dots-loading"
              visible={true}
            />
          </Center>
        ) : imageUrl || defaultImage ? (
          <Image
            src={imageUrl || defaultImage}
            width="120px"
            height="120px"
            borderRadius="60px"
            objectFit={"cover"}
          />
        ) : (
          <Icon name={"image"} size={"42px"} />
        )}
      </Stack>
      {!isLoading && (
        <>
          {!rounded ? (
            <Stack mt="16px" position={"relative"}>
              <Text
                onClick={
                  imageUrl
                    ? () => setMenuIsOpen(true)
                    : () => inputRef.current.click()
                }
                mt={"8px !important"}
                color={"Tertiary.dark"}
                fontSize={"L_md"}
                fontWeight={600}
                cursor={"pointer"}
              >
                {!imageUrl ? "Adicionar foto" : "Alterar foto"}
              </Text>

              {menuIsOpen && (
                <VStack
                  py={"8px"}
                  ref={menuRef}
                  left={"0"}
                  top={"40px"}
                  position={"absolute"}
                  zIndex={200}
                  background={"Gray.$100"}
                  minW={"240px"}
                  borderRadius={"12px"}
                  boxShadow={"0px 2px 16px rgba(0, 0, 0, 0.12)"}
                >
                  {menuOptions.map((option) => (
                    <Stack
                      cursor={"pointer"}
                      key={option.title}
                      padding={"12px 16px"}
                      onClick={() => {
                        setMenuIsOpen(false);
                        option.onClick();
                      }}
                      w={"100%"}
                      alignItems={"flex-start"}
                      transition={"300ms all"}
                      _hover={{
                        background: "Gray.$200",
                      }}
                    >
                      <Text
                        fontSize={"L_sm"}
                        fontWeight={400}
                        color={option.color}
                      >
                        {option.title}
                      </Text>
                    </Stack>
                  ))}
                </VStack>
              )}
            </Stack>
          ) : (
            <Center
              onClick={() => inputRef.current.click()}
              boxShadow={"0px 4px 16px 0px rgba(0, 0, 0, 0.12)"}
              position={"absolute"}
              bottom={0}
              right={0}
              w={"36px"}
              h={"36px"}
              borderRadius={"50%"}
              bg={"white"}
              cursor={"pointer"}
            >
              <Icon name="pen-solid" />
            </Center>
          )}
        </>
      )}

      <input
        id="file"
        type="file"
        ref={inputRef}
        accept=".png, .jpg"
        style={{ display: "none" }}
        onChange={(e) => {
          handleSelectImage(e.target.files?.item(0) as File);
        }}
      />
    </Stack>
  );
};

export default ProfilePic;
