import { Center, HStack, Text, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Loading } from "semente-js";
import { toast } from "react-toastify";
import { AddButton } from "src/components/AddButton";
import IndicatorService from "src/services/indicator";
import { Indicator } from "src/types";
import IndicatorEmptyState from "./EmptyState";
import IndicatorsList from "./List";
import { SubmitHandler } from "react-hook-form";
import IndicatorForm from "./Form";

const IndicatorTab: React.FC = () => {
  const [indicatorFormData, setIndicatorFormData] = useState<
    Indicator | {} | undefined
  >();
  const [indicators, setIndicators] = useState({
    data: [] as Indicator[],
    isLoading: true,
  });

  useEffect(() => {
    try {
      IndicatorService.getAll().then((indicators) =>
        setIndicators({ isLoading: false, data: indicators })
      );
    } catch (err) {
      toast.error("Ocorreu um erro ao buscar os indicadores");
    }
  }, []);

  const handleCreateOrUpdateIndicator: SubmitHandler<Indicator> = async (
    values
  ) => {
    try {
      if ("id" in values) {
        const newIndicator = await IndicatorService.update(values);
        const indicatorIndex = indicators.data.findIndex(
          (indicator) => indicator.id === values.id
        );
        const newIndicators = indicators.data;
        newIndicators[indicatorIndex] = newIndicator;

        setIndicators({ ...indicators, data: newIndicators });
      } else {
        const newIndicator = await IndicatorService.create(values);
        setIndicators({
          ...indicators,
          data: [...(indicators.data || []), newIndicator],
        });
      }
    } catch (err) {
      toast.error("Ocorreu um erro ao manusear seu indicador");
    }

    setIndicatorFormData(undefined);
  };

  const handleDeleteIndicator = async (indicator_id: Indicator["id"]) => {
    try {
      setIndicators({ ...indicators, isLoading: true });
      await IndicatorService.remove(indicator_id);
      setIndicators({
        isLoading: false,
        data: indicators.data.filter(
          (indicator) => indicator.id !== indicator_id
        ),
      });
    } catch (err) {
      toast.error("Ocorreu um erro ao remover este indicador.");
    }
  };

  return (
    <VStack flex={1} alignItems={"flex-start"} w={"100%"}>
      <IndicatorForm
        handleSubmitIndicator={handleCreateOrUpdateIndicator}
        handleClose={() => setIndicatorFormData(undefined)}
        indicator={indicatorFormData as Indicator}
      />

      <HStack justifyContent={"space-between"} width={"100%"} pb={"16px"}>
        <Text
          fontWeight={600}
          fontFamily={"Raleway"}
          fontSize={"RH_sm"}
          color={"Gray.$800"}
        >
          {"Indicadores"}
        </Text>

        {indicators.data && indicators.data.length >= 1 && (
          <AddButton
            label={`Adicionar novo indicador`}
            onClick={() => setIndicatorFormData({})}
          />
        )}
      </HStack>

      {indicators.isLoading ? (
        <Center mt={"64px !important"} w={"100%"}>
          <Loading
            height="100"
            width="100"
            color="#408EC5"
            secondaryColor="#408EC5"
            radius="12.5"
            ariaLabel="mutating-dots-loading"
            visible={true}
          />
        </Center>
      ) : (
        <>
          {indicators.data && (
            <>
              {indicators.data.length >= 1 ? (
                <IndicatorsList
                  indicators={indicators.data}
                  handleEditIndicator={setIndicatorFormData}
                  handleRemoveIndicator={handleDeleteIndicator}
                />
              ) : (
                <IndicatorEmptyState
                  handleCreate={() => setIndicatorFormData({})}
                />
              )}
            </>
          )}
        </>
      )}
    </VStack>
  );
};

export default IndicatorTab;
