import {
  HStack,
  VStack,
  Image,
  Center,
  Text,
  useTheme,
} from "@chakra-ui/react";
import React, { useCallback, useRef, useState } from "react";
import { Loading } from "semente-js";
import { CompanyWithProfile } from "src/types";
import Icon from "../../../../components/base/Icon";
import Input from "../../../../components/base/Input/Index";
import { Props } from "./types";
import CompanyService from "src/services/company";

export const ListLeftSide: React.FC<Props> = ({
  companies,
  handleSelectCompany,
  page,
  handleGetCompanies,
}) => {
  const theme = useTheme();
  const [timer, setTimer] = useState<any>(null);
  const [selectedId, setSelectedId] = useState(companies[0].id);
  const observer = useRef<any>(null);
  const [searchCompanies, setSearchCompanies] = useState<{
    isLoading: boolean;
    data?: CompanyWithProfile[];
  }>();

  const handleSearchProject = async (filter: string) => {
    if (filter) {
      setSearchCompanies({ isLoading: true, data: undefined });
      setSearchCompanies({
        isLoading: false,
        data: (await CompanyService.getCompaniesPaginated({ filter })).result,
      });
    } else {
      setSearchCompanies(undefined);
    }
  };

  const handleChangeInput = (e: string) => {
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(setTimeout(() => handleSearchProject(e), 500));
  };

  const lastItem = useCallback(
    (node: React.ReactNode) => {
      if (page.isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !page.isLast) {
          handleGetCompanies();
        }
      });
      if (node) observer.current.observe(node);
    },
    [handleGetCompanies, page]
  );

  return (
    <VStack maxW={"360px"} w={"100%"}>
      <Input
        placeholder={"Procurar empresa"}
        width={"100%"}
        onChange={(e: { target: { value: string } }) =>
          handleChangeInput(e.target.value)
        }
        icon={"search"}
      />

      {searchCompanies && searchCompanies.isLoading ? (
        <Center flex={1} mt={"32px !important"}>
          <Loading
            height="100"
            width="100"
            color="#408EC5"
            secondaryColor="#408EC5"
            radius="12.5"
            ariaLabel="mutating-dots-loading"
            visible={true}
          />
        </Center>
      ) : (
        <VStack w={"100%"} mt={"32px !important"}>
          {searchCompanies?.data && searchCompanies?.data?.length < 1 ? (
            <Text>Nenhuma empresa encontrada</Text>
          ) : (
            <VStack maxHeight={"600px"} overflow={"auto"} w={"100%"}>
              {(searchCompanies?.data || companies).map((company, key) => (
                <HStack
                  key={key}
                  w={"100%"}
                  background={
                    selectedId === company.id ? "Gray.$200" : "transparent"
                  }
                  borderRadius={"12px"}
                  alignItems={"center"}
                  cursor={"pointer"}
                  position={"relative"}
                  transition={"300ms all"}
                >
                  <HStack
                    padding={"16px"}
                    flex={1}
                    onClick={() => {
                      setSelectedId(company.id);
                      handleSelectCompany(company);
                    }}
                  >
                    <Center
                      width={"24px"}
                      height={"24px"}
                      borderRadius={"50%"}
                      background={"Gray.$300"}
                      mr={"12px !important"}
                    >
                      {company.image_url ? (
                        <Image
                          src={company.image_url}
                          borderRadius={"50%"}
                          objectFit={"cover"}
                          w={"100%"}
                          h={"100%"}
                        />
                      ) : (
                        <Icon name={"image"} color={theme.colors.Gray.$700} />
                      )}
                    </Center>
                    <VStack
                      alignItems={"flex-start"}
                      spacing={"0px !important"}
                      flex={1}
                    >
                      <Text
                        fontSize={"L_md"}
                        fontWeight={600}
                        color={"Gray.$800"}
                      >
                        {company.name}
                      </Text>
                    </VStack>
                  </HStack>
                </HStack>
              ))}

              {!searchCompanies?.data && (
                <Center ref={lastItem as any} w={"100%"} minH={"40px"}>
                  {page.isLoading && (
                    <Loading
                      height="100"
                      width="100"
                      color="#408EC5"
                      secondaryColor="#408EC5"
                      radius="12.5"
                      ariaLabel="mutating-dots-loading"
                      visible={true}
                    />
                  )}
                </Center>
              )}
            </VStack>
          )}
        </VStack>
      )}
    </VStack>
  );
};
