import {
  Box,
  Button,
  Center,
  HStack,
  Text,
  VStack,
  useTheme,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { Props } from "../types";
import { useDropzone } from "react-dropzone";
import Icon from "src/components/base/Icon";
import { formatDate } from "src/common";
import { Loading } from "semente-js";
import { saveAs } from "file-saver";
import DocumentsEmptyState from "./EmptyState";
import { AddButton } from "src/components/AddButton";
import { documentType, documentTypes, getDocumentsByType } from "./common";
import { Archive } from "src/types";
import ArchiveService from "src/services/archive";
import { uploadFileToBucket } from "src/utils";
import { toast } from "react-toastify";
import RemoveConfirmation from "./RemoveConfirmation";

const ArchivesSection: React.FC<Props> = ({ task }) => {
  const [currentDocumentType, setCurrentDocumentType] = useState<documentType>(
    Object.keys(documentTypes)[0] as keyof typeof documentTypes
  );
  const inputRef = useRef<any>(null);
  const [archiveToRemove, setArchiveToRemove] = useState<Archive>();
  const theme = useTheme();

  const [archives, setArchives] = useState({
    data: [] as Archive[],
    isLoading: true,
  });

  useEffect(() => {
    ArchiveService.getByTaskId(task.id).then((data) =>
      setArchives({ isLoading: false, data })
    );
  }, [task.id]);

  const handleSelectFile = async (files: any[]) => {
    try {
      setArchives({
        ...archives,
        isLoading: true,
      });

      const newArchives = (
        await Promise.all(
          (
            Array.from(
              !Array.isArray(files) ? (files as any)?.target?.files : files
            ) as File[]
          ).map(async (file) => {
            if (file.size <= 10000000) {
              const url = await uploadFileToBucket(file, {
                folderName: "TaskArchives",
                id: task.id,
              });

              return await ArchiveService.create({
                task_id: task.id,
                name: file.name,
                url,
              });
            }

            toast.error(
              "Um de seus itens ultrapassa o limite de 10 Megabytes."
            );
            return undefined as any;
          })
        )
      ).reduce((acc, item) => (!!item ? [...acc, item] : acc), []);

      setArchives({
        data: [...archives.data, ...newArchives],
        isLoading: false,
      });
    } catch (err) {
      toast.error("Ocorreu um erro ao fazer upload do arquivo");
      console.log(err);
    }
  };

  const { getRootProps, isDragActive, open } = useDropzone({
    onDrop: handleSelectFile,
  });

  const handleRemoveImage = async () => {
    if (archiveToRemove) {
      try {
        await ArchiveService.delete(archiveToRemove.id);
        setArchives({
          isLoading: false,
          data: archives.data.filter(
            (archive) => archive.id !== archiveToRemove.id
          ),
        });
        setArchiveToRemove(undefined);
      } catch (err) {
        toast.error("Ocorreu um erro ao remover sua imagem");
      }
    }
  };

  return (
    <motion.div
      initial={{ scale: 0.9 }}
      animate={{ scale: 1 }}
      style={{ width: "100%", height: "100%" }}
    >
      <VStack
        w={"100%"}
        h={"100%"}
        justifyContent={"space-between"}
        pb={"64px"}
      >
        <VStack
          w={"100%"}
          flex={1}
          px={"24px"}
          alignItems={"flex-start"}
          overflow={"auto"}
          spacing={0}
        >
          <HStack w={"100%"} mb={"24px "}>
            <Text
              flex={1}
              fontWeight={600}
              fontSize={"RH_sm"}
              fontFamily={"Raleway"}
            >
              Documentos
            </Text>

            {archives.data.length >= 1 && (
              <AddButton
                variant="Secondary"
                label="Novo documento"
                onClick={open}
              />
            )}
          </HStack>

          {archives.isLoading ? (
            <Center w={"100%"} h={"100%"}>
              <Loading
                height="100"
                width="100"
                color="#408EC5"
                secondaryColor="#408EC5"
                radius="12.5"
                ariaLabel="mutating-dots-loading"
                visible={true}
              />
            </Center>
          ) : (
            <>
              {archives.data.length >= 1 && (
                <HStack mb={"24px"}>
                  {(
                    Object.keys(documentTypes) as Array<
                      keyof typeof documentTypes
                    >
                  ).map((key) => (
                    <Button
                      key={key}
                      transition={"all 300ms "}
                      minH={"36px"}
                      variant={
                        currentDocumentType === key ? "Primary" : "solid"
                      }
                      fontWeight={500}
                      onClick={() => setCurrentDocumentType(key)}
                    >
                      {documentTypes[key].label}
                    </Button>
                  ))}
                </HStack>
              )}

              <Center
                h={"100%"}
                w={"100%"}
                m={"0px !important"}
                {...getRootProps({
                  onClick: (event) => event.stopPropagation(),
                })}
              >
                {isDragActive ? (
                  <Text
                    fontWeight={600}
                    fontFamily={"Raleway"}
                    fontSize={"RH_sm"}
                  >
                    Solte seu arquivo aqui.
                  </Text>
                ) : (
                  <>
                    {archives.data.length >= 1 ? (
                      <VStack h={"100%"} w={"100%"} alignItems={"flex-start"}>
                        <VStack w={"100%"} spacing={"0px !important"}>
                          {getDocumentsByType(
                            archives.data,
                            currentDocumentType
                          ).map((archive, index) => (
                            <VStack
                              key={archive.id}
                              width={"100%"}
                              alignItems={"flex-end"}
                            >
                              <HStack w={"100%"} py={"16px"}>
                                <Icon name={"file-blank"} size={"22px"} />
                                <HStack
                                  ml={"8px !important"}
                                  w={"100%"}
                                  justifyContent={"space-between"}
                                >
                                  <VStack
                                    alignItems={"flex-start"}
                                    spacing={"4px !important"}
                                    width={"100%"}
                                  >
                                    <Text fontSize={"L_md"}>
                                      {archive.name}
                                    </Text>
                                    <Text fontSize={"L_md"} color={"Gray.$700"}>
                                      Carregado em{" "}
                                      {formatDate(
                                        new Date(archive.createdAt),
                                        "dd/MM/yyyy"
                                      )}
                                    </Text>
                                  </VStack>

                                  <Button
                                    onClick={() =>
                                      saveAs(archive.url, archive.name)
                                    }
                                    minH={"36px"}
                                  >
                                    <HStack>
                                      <Icon name={"arrow-circle-down-solid"} />
                                      <Text
                                        cursor={"pointer"}
                                        fontWeight={600}
                                        fontSize={"L_md"}
                                      >
                                        Baixar
                                      </Text>
                                    </HStack>
                                  </Button>

                                  <Button
                                    onClick={() => setArchiveToRemove(archive)}
                                    borderRadius={"50%"}
                                    minH={"32px"}
                                    w={"32px"}
                                  >
                                    <Icon
                                      name={"trash-alt-solid"}
                                      color={theme.colors.Red.pure}
                                      size="18px"
                                    />
                                  </Button>
                                </HStack>
                              </HStack>

                              {archives.data.length - 1 !== index && (
                                <Box
                                  m={"0px !important"}
                                  w={"94%"}
                                  h={"1px"}
                                  backgroundColor={"Gray.$400"}
                                />
                              )}
                            </VStack>
                          ))}
                        </VStack>
                      </VStack>
                    ) : (
                      <DocumentsEmptyState handleAdd={open} />
                    )}
                  </>
                )}
              </Center>
            </>
          )}
        </VStack>
      </VStack>

      <input
        id="file"
        type="file"
        multiple
        ref={inputRef}
        style={{ display: "none" }}
        onChange={(e: any) => handleSelectFile(e)}
      />

      <RemoveConfirmation
        document={archiveToRemove}
        handleConfirm={handleRemoveImage}
        handleClose={() => setArchiveToRemove(undefined)}
      />
    </motion.div>
  );
};

export default ArchivesSection;
